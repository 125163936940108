import {arrayOf, func, object, shape} from "prop-types"

import ContentLibrary from "./content-library"

const setMessages = payload => ({type: "MESSAGES_SET", payload})

const AddContentStep = ({currentRow: {messages}, dispatch}) => (
  <ContentLibrary onChange={value => dispatch(setMessages(value))} selectedMessages={messages} />
)

AddContentStep.propTypes = {
  currentRow: shape({
    messages: arrayOf(object),
  }),
  dispatch: func.isRequired,
}

export default AddContentStep
