import {push} from "connected-react-router"
import queryString from "query-string"

import {clearAccessToken, setAccessToken} from "lib/access-token"
import {authenticate, deleteToken, fetchCurrentUser, fetchUsers} from "lib/api"
import apiActionCreator from "lib/api-action-creator"
import storage from "lib/storage"

export const CURRENT_TEAM_SET = "CURRENT_TEAM_SET"
export const CURRENT_TEAM_USERS_SET = "CURRENT_TEAM_USERS_SET"
export const CURRENT_USER_SET = "CURRENT_USER_SET"
export const SESSION_CLEARED = "SESSION_CLEARED"
export const AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR"

export const loginUser = ({rememberMe, ...attrs} = {}) => dispatch =>
  authenticate(attrs)
    .then(token => {
      const {redirect} = queryString.parse(window.location.search)

      setAccessToken(storage, token, rememberMe)

      dispatch(push(redirect || "/admin/templates"))
    })
    .catch(err => {
      const message =
        err.message === "invalid_credentials"
          ? "The username or password you entered was incorrect."
          : "Something went wrong, please try again."

      dispatch({type: AUTHENTICATION_ERROR, payload: message})
    })

export const setCurrentTeam = payload => ({type: CURRENT_TEAM_SET, payload})
export const setCurrentUser = payload => ({type: CURRENT_USER_SET, payload})

export const getCurrentUser = apiActionCreator(CURRENT_USER_SET, fetchCurrentUser)

export const getUsers = () => dispatch =>
  fetchUsers().then(users => dispatch({type: CURRENT_TEAM_USERS_SET, payload: users}))

export const clearSession = () => ({type: SESSION_CLEARED})

export const onLogout = dispatch => () =>
  deleteToken().then(() => {
    clearAccessToken(storage)
    dispatch({type: SESSION_CLEARED})
    dispatch(push("/admin/login"))
  })
