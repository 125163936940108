import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func, oneOf, shape} from "prop-types"
import {useState} from "react"

import ContentLibrary from "components/campaign-builder/mass-messaging/content-library"

import {useTemplateContentContext} from "../../../contexts/template-content-context"

const useDialogStyles = makeStyles(() => ({
  dialog: {
    maxWidth: 800,
  },
}))

const MessageAddDialog = ({messageSelectorOptions, setMessageSelectorOptions}) => {
  const classes = useDialogStyles()

  const {completeTemplateMessageAddition} = useTemplateContentContext()

  if (!messageSelectorOptions) return null

  const {queue, type} = messageSelectorOptions

  const onCancel = () => {
    setMessageSelectorOptions(null)
  }

  const onComplete = messages => {
    setMessageSelectorOptions(null)
    const templateMessages = messages.map(message => {
      return {
        message,
        queue,
      }
    })

    completeTemplateMessageAddition(templateMessages)
  }

  return (
    <Dialog
      classes={{paper: classes.dialog}}
      fullWidth={true}
      maxWidth={false}
      onClose={onCancel}
      open={true}
    >
      <MessageSelection onCancel={onCancel} onComplete={onComplete} type={type} />
    </Dialog>
  )
}

MessageAddDialog.propTypes = {
  messageSelectorOptions: shape({
    queue: oneOf(["draft", "nurturing", "scheduled"]),
    type: oneOf(["email", "sms"]),
  }),
  setMessageSelectorOptions: func.isRequired,
}

const useMessageSelectionStyles = makeStyles(theme => ({
  header: {
    zIndex: 1,
    boxShadow: "0px -8px 10px 8px rgba(0,0,0,0.2)",
  },
  footer: {
    zIndex: 1,
    boxShadow: "0px 8px 10px 8px rgba(0,0,0,0.2)",
  },
  content: {
    width: "100%",
    height: 550,
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(2),
  },
}))

const MessageSelection = ({onCancel, onComplete}) => {
  const [selectedMessages, setSelectedMessages] = useState([])

  const classes = useMessageSelectionStyles()

  return (
    <>
      <DialogTitle className={classes.header}>Add new message</DialogTitle>
      <DialogContent classes={{root: classes.content}}>
        <ContentLibrary onChange={setSelectedMessages} selectedMessages={selectedMessages} />
      </DialogContent>
      <DialogActions className={classes.footer}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          color="primary"
          disabled={selectedMessages.length === 0}
          onClick={() => onComplete(selectedMessages)}
          variant="contained"
        >
          {selectedMessages.length > 0 ? `Add message` : "Select a message"}
        </Button>
      </DialogActions>
    </>
  )
}

MessageSelection.propTypes = {
  onCancel: func.isRequired,
  onComplete: func.isRequired,
  type: oneOf(["sms", "email"]).isRequired,
}

export default MessageAddDialog
