import {Typography} from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import LinearProgress from "@mui/material/LinearProgress"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import get from "lodash/get"
import {arrayOf, object, shape, string} from "prop-types"
import {useEffect} from "react"
import {animated, useSpringValue} from "react-spring"

import {journeyContext} from "../journeys/journey-context"

const useStyles = makeStyles(theme => ({
  progressBase: {
    "&.circular": {
      "& .square": {
        width: "100%",
        paddingBottom: "100%",
      },
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      "& .info": {
        textAlign: "center",
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        borderRadius: "50%",
        boxShadow: theme.shadows[2],
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: "#FFF",
        transition: theme.transitions.create(["background-color"], {duration: 400}),
        "&.isCompleted": {
          animation: "reward-complete-animation .25s 4 alternate",
          animationDelay: "2s",
          transformOrigin: "center",
          background: theme.palette.primary.main,
          "& *": {
            transition: theme.transitions.create(["color"], {duration: 400}),
            color: theme.palette.primary.contrastText,
          },
        },
      },
    },
  },
  linearRoot: {
    boxShadow: theme.shadows[2],
  },
  circle: {
    strokeLinecap: "round",
  },
  progressInset: {
    position: "absolute",
    top: 10,
    right: 10,
    bottom: 10,
    left: 10,
    borderRadius: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFF",
  },
  "@keyframes reward-complete-animation": {
    to: {
      boxShadow: "0 0 0 10px #eda40f",
      transform: "scale(1.3)",
    },
  },
  progressSubtitle: {
    color: "rgba(0, 0, 0, 0.87)", // Reset after branding overrides.
    fontSize: "12px",
    fontWeight: "normal", // Reset after branding overrides.
    lineHeight: "normal", // Reset after branding overrides.
    letterSpacing: "normal", // Reset after branding overrides.
    marginBottom: "0 !important", // Reset after branding overrides.
  },
  progressComplete: {},
  progressCompletedPercentage: {
    marginTop: -2,
    color: "rgba(0, 0, 0, 0.87)", // Reset after branding overrides.
    display: "block",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
}))

const interpolatePercentage = (str, percentage) => {
  return (str || "").replace("%%", `${Math.round(percentage)}%`)
}

export function Progress({analyticsCache, color, type, subtitle, reward, pages}) {
  const classes = useStyles()

  const navigablePageIds = pages.map(page => page.id)
  const totalPagesForCompletion = pages.filter(
    page => page.completionCondition && navigablePageIds.includes(page.id)
  ).length
  const pagesCompleted = get(analyticsCache, "pagesCompleted", []).filter(pageId =>
    navigablePageIds.includes(pageId)
  ).length

  const completionTotal = Math.min(1, pagesCompleted / totalPagesForCompletion)

  const percentCompleted = Math.floor(completionTotal * 100)
  const isCompleted = get(analyticsCache, "isCompleted", false)

  /* eslint-disable-next-line react-hooks/rules-of-hooks */
  const accrued = useSpringValue(0, {delay: 500})

  const infoStyles = {}

  if (isCompleted && type === "circular" && color) infoStyles.backgroundColor = color

  useEffect(() => {
    accrued.start(percentCompleted)
  }, [accrued, percentCompleted])

  return totalPagesForCompletion ? (
    <>
      <div className={cx(classes.progressBase, type)}>
        <div className="square" />
        <div className={cx("info", {isCompleted})} style={infoStyles}>
          <Typography className={classes.progressCompletedPercentage} component="em">
            <animated.span>
              {accrued.to(number => interpolatePercentage(reward, number))}
            </animated.span>
          </Typography>
          <Typography className={classes.progressSubtitle}>
            <animated.span>
              {accrued.to(number => interpolatePercentage(subtitle, number))}
            </animated.span>
          </Typography>
        </div>
      </div>
      {type === "linear" ? (
        <LinearProgress
          classes={{root: classes.linearRoot}}
          color="inherit"
          style={{color}}
          variant="determinate"
          size="100%"
          thickness={3}
          value={percentCompleted}
        />
      ) : (
        <CircularProgress
          classes={{circle: classes.circle}}
          style={{color, height: "auto"}}
          variant="determinate"
          size="100%"
          thickness={3}
          value={percentCompleted}
        />
      )}
    </>
  ) : null
}

Progress.propTypes = {
  analyticsCache: object,
  color: string,
  pages: arrayOf(
    shape({
      id: string,
      completionCondition: string,
    })
  ).isRequired,
  reward: string,
  subtitle: string,
  type: string,
}

Progress.defaultProps = {
  type: "circular",
}

export default journeyContext(Progress)
