import {Box, Grid, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {zipObject} from "lodash"
import {useMemo} from "react"

import EnrollmentWidget from "components/template-insights/enrollment-widget"
import {useInsights} from "components/template-insights/insights-context"
import OptInWidget from "components/template-insights/opt-in-widget"

import CardOnFileMerchantDetails from "./card-on-file-merchant-details"
import CardOnFileOverview from "./card-on-file-overview"
import CardOnFileTotalSwitches from "./card-on-file-total-switches"
import DirectDepositDonut from "./direct-deposit-donut"
import MobileAppDonut from "./mobile-app-donut"
import SecurePersonalization from "./secure-personalization"
import SurveyCompletions from "./survey-completions"

const useStyles = makeStyles(theme => ({
  header: {
    fontSize: 36,
    fontWeight: 200,
    marginBottom: 20,
  },
  subheader: {
    fontSize: theme.typography.fontSize * 1.75,
    fontWeight: 100,
  },
  coffSubheader: {
    fontSize: theme.typography.fontSize * 1.5,
  },
  section: {
    marginBottom: 50,
  },
}))

const WidgetPerformance = () => {
  const {hasWidget} = useInsights()

  const classes = useStyles()

  const [showInsightWidget, showSectionWidgets] = useMemo(() => {
    const sectionWidgets = ["mobile-app-download", "opt-in", "direct-deposit", "enrollment"]
    const insightWidgets = [...sectionWidgets, "card-on-file", "survey"]

    const hasInsightWidget = insightWidgets.map(name => hasWidget(name))
    const showInsightWidget = zipObject(insightWidgets, hasInsightWidget)

    const showSectionWidgets = sectionWidgets.reduce(
      (acc, name) => acc || showInsightWidget[name],
      false
    )
    return [showInsightWidget, showSectionWidgets]
  }, [hasWidget])

  return (
    <>
      <Typography className={classes.header} variant="h5">
        Widgets &amp; tools
      </Typography>

      {showSectionWidgets && (
        <div className={classes.section}>
          {showInsightWidget["mobile-app-download"] && <MobileAppDonut classes={classes} />}
          {showInsightWidget["opt-in"] && <OptInWidget classes={classes} />}
          {showInsightWidget["direct-deposit"] && <DirectDepositDonut classes={classes} />}
          {showInsightWidget["enrollment"] && <EnrollmentWidget classes={classes} />}
          <SecurePersonalization classes={classes} />
        </div>
      )}

      {showInsightWidget["card-on-file"] && (
        <Box sx={{mb: 6}}>
          <Typography className={cx(classes.subheader, classes.coffHeader)} variant="h6">
            Card on File
          </Typography>

          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
              <CardOnFileOverview classes={classes} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
              <CardOnFileMerchantDetails classes={classes} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
              <CardOnFileTotalSwitches classes={classes} />
            </Grid>
          </Grid>
        </Box>
      )}

      {showInsightWidget["survey"] && (
        <Box sx={{mb: 6}}>
          <Typography className={classes.subheader} sx={{mb: 2}} variant="h6">
            Survey
          </Typography>
          <Typography sx={{fontSize: "fontSize", fontWeight: 100, mb: 4}} variant="h6">
            Graphs are not shown for open-ended survey questions like Short Answer or Paragraph.
            These questions are required to be answered to submit the survey, and can take any
            answer, so there is little to categorize and report.
          </Typography>
          <SurveyCompletions />
        </Box>
      )}
    </>
  )
}

export default WidgetPerformance
