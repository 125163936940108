import {Button, Link, MenuItem, TableCell, TableRow, Tooltip, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {compareAsc} from "date-fns"
import {arrayOf, func, object, oneOfType, shape, string} from "prop-types"
import {FaArchive as ArchiveIcon, FaEye as PreviewIcon} from "react-icons/fa"

import BadgeIfError from "components/badge-if-error/badge-if-error"
import {Preview} from "components/content-library/content-preview"
import {humanizeType} from "components/content-library/helpers"
import DOSelect from "components/do-select/do-select"
import IconTooltip from "components/icon-tooltip/icon-tooltip"
import {messageHasInvalidCta} from "components/template-content/nurturing-message-row"

const useStyles = makeStyles(theme => ({
  archived: {
    position: "relative",

    "&::before": {
      borderBottom: "60px solid transparent",
      borderLeft: `60px solid ${theme.palette.primary.main}`,
      content: `""`,
      height: 0,
      left: 0,
      position: "absolute",
      top: 0,
      width: 0,
    },
  },
  isPast: {
    opacity: 0.8,
  },
  archiveIcon: {
    color: theme.palette.primary.contrastText,
    fontSize: 20,
    left: -8,
    position: "relative",
    top: -17,
  },
  row: {
    "& .showOnHover": {
      visibility: "hidden",
    },
    "&:hover .showOnHover": {
      visibility: "visible",
    },
  },
  edit: {
    textTransform: "uppercase",
    cursor: "pointer",
  },
  actionCell: {
    display: "flex",
    "& > button:first-of-type": {
      marginRight: theme.spacing(1),
    },
  },
  preview: {
    width: 306,
  },
  previewTooltip: {
    maxWidth: "none",
  },
  destructiveAction: {
    color: theme.palette.error.main,
  },
  actionText: {
    cursor: "pointer",
    "$isPast &": {
      cursor: "auto",
    },
  },
  picker: {
    marginTop: 8,
  },
  scheduledAt: {
    "& input": {
      cursor: "pointer",
      width: 155,
      fontSize: 14,
    },
  },
  disabled: {
    "& input": {
      cursor: "auto",
    },
  },
  dateInput: {
    width: 100,
  },
  timeInput: {
    width: 100,
  },
  select: {
    fontSize: 14,
    maxWidth: 200,
  },
}))

const ScheduledMessageRow = ({
  column,
  row,
  editTemplateMessage,
  launchedAt,
  templatePages,
  templateStatus,
  updateTemplateMessage,
}) => {
  const classes = useStyles()

  // `row` is a templateMessage record, so we rename it for clarity.
  const templateMessage = row
  const {id, messageId, message, queue, templatePageId} = templateMessage
  const {contentName, isArchived, objective} = message
  const messageType = humanizeType(message.type)
  // Offset to local timezone from persisted naive date
  // setting to null until we introduce scheduling
  const humanizedLaunchedAt = launchedAt ? new Date(launchedAt) : null
  const isPast = compareAsc(new Date(), launchedAt) > 0
  const isDraft = queue === "draft"
  const status = isDraft ? "Draft" : launchedAt ? `Sent at ${humanizedLaunchedAt}` : "Scheduled"

  const onChangeIneligibilityCondition = ({target: {value}}) =>
    updateTemplateMessage(id, {
      templatePageId: value === "objective" ? null : value,
    })

  const handleScheduleMessage = () => {
    updateTemplateMessage(id, {
      queue: "scheduled",
    })
  }

  const hasArchivedColumn = column.find(c => c.field === "archived")

  return (
    <TableRow
      className={cx(classes.row, {[classes.isPast]: isPast})}
      tabIndex={0}
      data-testid="scheduled-messaging-row"
    >
      {hasArchivedColumn && (
        <TableCell
          className={cx({[classes.archived]: isArchived})}
          component="td"
          data-testid="archived-column"
          scope="row"
        >
          {isArchived && (
            <IconTooltip
              iconClassName={classes.archiveIcon}
              iconComponent={ArchiveIcon}
              title={"This message is archived, but will still work as normal for this campaign."}
            />
          )}
        </TableCell>
      )}
      <TableCell
        className={cx(classes.scheduledAt, {[classes.disabled]: isPast})}
        component="td"
        scope="row"
      >
        <Typography variant="body2">{templateStatus === "live" ? "Live" : "Pending"}</Typography>
      </TableCell>
      <TableCell component="td" scope="row">
        <Typography variant="body2">{status}</Typography>
      </TableCell>
      <TableCell>
        <Tooltip
          classes={{tooltip: classes.previewTooltip}}
          placement="right"
          title={
            <div className={classes.preview}>
              <Preview
                contentType={messageType.toLowerCase()}
                id={messageId}
                smsPreviewText={message.payload?.body?.blocks[0]?.text}
              />
            </div>
          }
        >
          <div>
            <PreviewIcon />
          </div>
        </Tooltip>
      </TableCell>
      <TableCell component="td" scope="row" data-testid="message-content-name">
        <BadgeIfError
          badgeText="This message contains a CTA with an invalid or incomplete link."
          hasError={messageHasInvalidCta(templateMessage, templatePages)}
        >
          <Typography
            className={classes.actionText}
            onClick={isPast ? () => {} : () => editTemplateMessage(id)}
            title={`Edit ${contentName}`}
            variant="body2"
          >
            {contentName}
          </Typography>
        </BadgeIfError>
      </TableCell>
      <TableCell component="td" scope="row">
        <Typography variant="body2">{messageType}</Typography>
      </TableCell>
      <TableCell component="td" scope="row">
        <DOSelect
          className={classes.select}
          disabled={isPast}
          fullWidth={true}
          onChange={onChangeIneligibilityCondition}
          value={templatePageId || "objective"}
        >
          {objective ? (
            <MenuItem key="objective" value="objective">
              Objective Completed
            </MenuItem>
          ) : (
            <MenuItem key="objective" value="objective">
              Always Send
            </MenuItem>
          )}
          {templatePages?.map(tp => (
            <MenuItem key={tp.id} value={tp.id}>
              Completed {tp.page.contentName}
            </MenuItem>
          ))}
        </DOSelect>
      </TableCell>
      <TableCell component="td" scope="row">
        <Typography variant="body2">{objective?.name || "(None)"}</Typography>
      </TableCell>
      <TableCell component="td" scope="row">
        {isDraft ? (
          <Button
            color="primary"
            disabled={isPast}
            onClick={handleScheduleMessage}
            variant="contained"
          >
            Schedule
          </Button>
        ) : (
          <Link color="black" onClick={() => editTemplateMessage(id)}>
            Edit Template
          </Link>
        )}
      </TableCell>
    </TableRow>
  )
}

ScheduledMessageRow.propTypes = {
  column: arrayOf(
    shape({
      field: string.isRequired,
    })
  ).isRequired,
  editTemplateMessage: func.isRequired,
  row: object.isRequired,
  launchedAt: string,
  setTemplateMessageToRemove: func.isRequired,
  templatePages: arrayOf(
    shape({
      id: string.isRequired,
      page: shape({
        contentName: string,
      }),
    })
  ),
  templateStatus: oneOfType(["draft", "pending-approval", "approved", "live", "archived"]),
  updateTemplateMessage: func.isRequired,
}

export default ScheduledMessageRow
