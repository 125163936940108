import {Box, Button, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {array, bool, func, object, string} from "prop-types"
import {useEffect, useState} from "react"
import {FaCheck, FaTimes} from "react-icons/fa"

import {useTemplateContentContext} from "contexts/template-content-context"
import {fetchTemplateTargeting} from "lib/api"

const StatusIndicator = ({classes, label, isValid}) => {
  return (
    <div className={classes.statusIndicatorParent}>
      {isValid ? (
        <FaCheck style={{color: "green", marginRight: "4px"}} />
      ) : (
        <FaTimes style={{color: "red", marginRight: "4px"}} />
      )}
      <Typography variant="body2" style={{color: isValid ? "green" : "red"}}>
        {label}
      </Typography>
    </div>
  )
}

const CampaignLaunchingSpinner = ({classes, setShowLaunchCampaignConfirmDialog}) => {
  const {templateId, templateMessages} = useTemplateContentContext()
  const [validAudience, setValidAudience] = useState(false)
  const [validContent, setValidContent] = useState(false)
  const [validToSend, setValidToSend] = useState(false)
  const hasTemplateMessage = templateMessages.length === 1
  const hasPersonalization = templateMessages[0]?.message?.hasPersonalization ?? false

  useEffect(() => {
    fetchTemplateTargeting(templateId).then(({targetingGroups, targetingImpact}) => {
      const hasTargetingGroups = targetingGroups.length > 0
      const validatedAudience = hasTargetingGroups && !targetingImpact
      setValidAudience(validatedAudience)
      const validatedContent = hasTemplateMessage && !hasPersonalization
      setValidContent(validatedContent)
      setValidToSend(validatedAudience && validatedContent)
    })
  }, [hasPersonalization, hasTemplateMessage, templateId, templateMessages])

  return (
    <>
      <Box className={classes.statusIndicatorGroup} gap={2}>
        <StatusIndicator classes={classes} label="Audience selected" isValid={validAudience} />
        <StatusIndicator
          classes={classes}
          label="Template with no personalization"
          isValid={validContent}
        />
      </Box>
      <Button
        classes={classes}
        color="primary"
        disabled={!validToSend}
        variant="contained"
        style={{marginRight: 10}}
        onClick={() => setShowLaunchCampaignConfirmDialog(true)}
      >
        Send Now
      </Button>
    </>
  )
}

CampaignLaunchingSpinner.propTypes = {
  classes: object,
  setShowLaunchCampaignConfirmDialog: func,
}
StatusIndicator.propTypes = {classes: array, label: string, isValid: bool}

const styles = theme => ({
  statusIndicatorGroup: {
    display: "flex",
    alignItems: "flex-start",
  },
  statusIndicatorParent: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
})

export default withStyles(styles)(CampaignLaunchingSpinner)
