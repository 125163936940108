import querystring from "query-string"

const YOUTUBE = /youtube\.com$/
const YOUTUBE_SHORT = /youtu\.be$/
const VIMEO = /vimeo\.com$/
const WISTIA = /(?:wistia\.com|wistia\.net|wi\.st)$/

export const dataToUrl = ({service, id}) => {
  switch (service) {
    case "youtube":
    case "youtube-shorts":
      return `https://www.youtube.com/embed/${id}?rel=0`
    case "vimeo":
      return `https://player.vimeo.com/video/${id}?badge=0`
    default:
      return ""
  }
}

const findUrl = rawEmbed => {
  const url = rawEmbed.match(/(https?:\/\/[^\s"']*)/)
  if (url) return new URL(url[1])
  else return false
}

const idFromPath = (path, after) => {
  const parts = path.split("/")

  return parts[parts.findIndex(part => part === after) + 1]
}

export const embedToData = rawEmbed => {
  const url = findUrl(rawEmbed)

  if (!url) return {}

  if (url.host.match(YOUTUBE)) {
    // for long links https://www.youtube.com/watch?v=dQw4w9WgXcQ

    // detects if it's a normal video or a YT Shorts
    const [service, pathBeforeId] = url.pathname.includes("shorts")
      ? ["youtube-shorts", "shorts"]
      : ["youtube", "embed"]

    return {
      service,
      id: querystring.parse(url.search).v || idFromPath(url.pathname, pathBeforeId),
    }
  }

  if (url.host.match(YOUTUBE_SHORT))
    // For short links https://youtu.be/dQw4w9WgXcQ
    // YT shorts videos don't have short links so don't have to handle them here
    return {
      service: "youtube",
      id: url.pathname.split("/")[1],
    }

  if (url.host.match(VIMEO))
    return {
      service: "vimeo",
      id: idFromPath(url.pathname, "video") || url.pathname.split("/")[1],
    }

  if (url.host.match(WISTIA)) {
    return {
      service: "wistia",
      id:
        idFromPath(url.pathname, "medias") ||
        idFromPath(url.pathname, "iframe") ||
        idFromPath(url.pathname, "embed"),
    }
  }
}
