import {Button, FormControl, InputLabel, MenuItem, Select, Typography} from "@mui/material"
import {alpha} from "@mui/material/styles"
import withStyles from "@mui/styles/withStyles"
import {ContentState, convertFromHTML, convertToRaw} from "draft-js"
import {fromJS} from "immutable"
import debounce from "lodash/debounce"
import memoize from "memoize-one"
import {array, bool, func, object, shape, string} from "prop-types"
import {PureComponent} from "react"
import {FaMinusCircle as MinusCircle, FaUpload as Upload} from "react-icons/fa"
import {Field, reduxForm} from "redux-form"

import {RFTextField} from "components/mui-redux-form/mui-redux-form"

import {fullName} from "lib/names"

import AccessControlled, {controlAccess} from "../access-control/access-controlled"
import FileBrowser from "../file-browser/file-browser"
import UrlBox from "../journeys-show/url-box"
import RTE from "../rte/rte"

class LandingPageSettings extends PureComponent {
  state = {
    isFileBrowserOpen: false,
    supportOwnerId: this.props.currentUser.id,
  }

  getInitialContentState = memoize(content => {
    if (!content) return null

    const blocksFromHTML = convertFromHTML(content)

    if (!blocksFromHTML.contentBlocks) return null

    return fromJS(
      convertToRaw(
        ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
      )
    )
  })

  onChange = () => this.props.handleSubmit()
  debouncedOnChange = debounce(this.onChange, 1000)
  onOpenBrowser = () => this.setState({isFileBrowserOpen: true})
  onCloseBrowser = () => this.setState({isFileBrowserOpen: false})
  landingPageBackgroundRef = el => (this.landingPageBackground = el)
  onSelectImage = file => {
    this.props.change("backgroundFileId", file.id)
    this.debouncedOnChange()
    this.onCloseBrowser()
  }
  onRemoveBackground = () => {
    this.props.change("backgroundFileId", null)
    this.debouncedOnChange()
  }
  onUpdateContent = ({target}) => {
    this.props.change("content", target.htmlValue)
    this.debouncedOnChange()
  }

  onSelectSupportOwner = ({target}) => {
    this.setState({supportOwnerId: target.value})
  }

  render() {
    const {classes, landingPage, users, disabled} = this.props
    const {supportOwnerId} = this.state
    const supportOwner = users.find(user => user.id === supportOwnerId)
    const initialState = this.getInitialContentState(landingPage.content)

    return (
      <form className={classes.container} onChange={this.debouncedOnChange}>
        <div className={classes.flex}>
          <Field component="input" disabled={disabled} name="id" type="hidden" />

          <Field component="input" disabled={disabled} name="backgroundFileId" type="hidden" />
          <Button
            color="primary"
            disabled={disabled}
            onClick={this.onOpenBrowser}
            size="small"
            style={{margin: "15px 0"}}
            type="button"
            variant="contained"
          >
            <Upload style={{marginRight: 15}} /> Upload background image
          </Button>
          <div className="landing-page-background">
            {landingPage.backgroundFileId && (
              <span>
                <img
                  alt=""
                  className={classes.backgroundPreview}
                  crossOrigin="anonymous"
                  src={`${process.env.REACT_APP_API_URL}/files/${landingPage.backgroundFileId}?variant=large`}
                />
                <button
                  className={classes.removeBackgroundButton}
                  onClick={this.onRemoveBackground}
                  title="Remove"
                >
                  <MinusCircle />
                </button>
              </span>
            )}
          </div>
          {this.state.isFileBrowserOpen && (
            <FileBrowser
              isOpen={this.state.isFileBrowserOpen}
              onClose={this.onCloseBrowser}
              onSelect={this.onSelectImage}
              types={["image/png", "image/gif", "image/jpeg"]}
            />
          )}

          <Field
            component={RFTextField}
            disabled={disabled}
            fullWidth={true}
            label="Title"
            margin="normal"
            name="title"
            placeholder="Start your journey..."
          />

          <Typography component="label" style={{paddingTop: 20}}>
            Content
          </Typography>
          <RTE
            initialState={initialState}
            key={`lps-rte-${landingPage.id}`}
            name="content"
            onChange={this.onUpdateContent}
            readOnly={disabled}
          />

          <Field
            component={RFTextField}
            disabled={disabled}
            fullWidth={true}
            label="Submit Title"
            margin="normal"
            name="submitTitle"
            placeholder="Continue..."
          />
        </div>
        <div className={`${classes.flex} ${classes.urls}`}>
          {landingPage.id ? (
            <>
              <Typography className={classes.subtitle} variant="subtitle1">
                Regular Mode
              </Typography>
              <p>
                When the landing page is accessed with this address users will be sent directly to
                the journey after they enter their information.
              </p>
              <UrlBox
                className={classes.urlBox}
                url={`lp/${this.props.template.id}?ownerId=${supportOwnerId}`}
              />
              <Typography className={classes.subtitle} variant="subtitle1">
                Kiosk Mode
              </Typography>
              <p>
                When the landing page is accessed with this address users will be shown a short
                thank you message but their journey will not be loaded automatically. The form will
                reset after a few seconds.
              </p>
              <UrlBox
                className={classes.urlBox}
                url={`lp/${this.props.template.id}?ownerId=${supportOwnerId}&kiosk_mode=true`}
              />
              <p>
                Journeys created from the urls above will have <b>{fullName(supportOwner)}</b> as a
                support owner. To see landing page links for a different user, select them from the
                list below.
              </p>
              <AccessControlled requiredPermissions="templates:support_owner">
                <FormControl>
                  <InputLabel htmlFor="ownerId">Owner</InputLabel>
                  <Select
                    inputProps={{
                      name: "ownerId",
                      id: "ownerId",
                    }}
                    onChange={this.onSelectSupportOwner}
                    value={supportOwnerId}
                  >
                    {users.map(user => (
                      <MenuItem key={user.id} value={user.id}>
                        {fullName(user)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </AccessControlled>
            </>
          ) : (
            <p>
              <i>
                A URL will appear once a landing page exists (by setting one of the above fields)
              </i>
            </p>
          )}
        </div>
      </form>
    )
  }
}

LandingPageSettings.propTypes = {
  change: func.isRequired,
  classes: object,
  currentUser: shape({
    id: string,
  }).isRequired,
  disabled: bool,
  handleSubmit: func.isRequired,
  landingPage: shape({
    backgroundFileId: string,
    content: string,
    id: string,
  }).isRequired,
  template: shape({
    id: string,
    ownerId: string,
  }),
  users: array.isRequired,
}

const styles = theme => ({
  container: {
    marginTop: 50,
    [theme.breakpoints.up("1000")]: {
      display: "flex",
    },
  },
  flex: {
    [theme.breakpoints.up("1000")]: {
      flex: 1,
      maxWidth: "50%",
      "& +$flex": {
        marginLeft: theme.spacing(4),
      },
    },
  },
  backgroundPreview: {
    maxWidth: "100%",
  },
  urls: {
    backgroundColor: alpha(theme.palette.primary.light, 0.1),
    padding: theme.spacing(1),
  },
  urlBox: {
    margin: `${theme.spacing(4)} 0`,
  },
  subtitle: {
    color: theme.palette.primary.dark,
  },
  removeBackgroundButton: {
    border: "none",
    fontSize: 16,
    cursor: "pointer",
  },
})

export default withStyles(styles)(
  reduxForm({form: "landingPage", enableReinitialize: true})(
    controlAccess({requiredPermissions: "templates:edit"})(LandingPageSettings)
  )
)
