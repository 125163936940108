import {Box, Button, ButtonGroup, FormControl, Grid, TextField} from "@mui/material"
import {ThemeProvider} from "@mui/material/styles"
import makeStyles from "@mui/styles/makeStyles"
import {func, object, string} from "prop-types"
import {useState} from "react"
import {connect} from "react-redux"

import FileBrowser from "components/file-browser/file-browser"
import HeaderLogo from "components/journeys/header-logo"
import {brandingSettings} from "components/teams/branding-settings/branding-settings-context"

const LogoSettings = ({
  field,
  logoFileId,
  onChangeLogoFileId,
  onRemoveLogoFileId,
  template,
  muiTheme,
}) => {
  const [isFileBrowserOpen, setIsFileBrowserOpen] = useState(false)

  const classes = useStyles()

  const onSetLogo = file => {
    onChangeLogoFileId(file)
    setIsFileBrowserOpen(false)
  }

  return (
    <Grid container spacing={3}>
      <Grid item lg={6} md={12} xs={12}>
        <Box mb={4}>
          {template && logoFileId ? (
            <ThemeProvider theme={muiTheme}>
              <HeaderLogo
                cacheBuster={template.updatedAt}
                src={`${process.env.REACT_APP_API_URL}/files/${logoFileId}`}
                variant="large"
              />
            </ThemeProvider>
          ) : (
            <div className={classes.noFile}>no file</div>
          )}
          <FormControl margin="normal" fullWidth={true}>
            <ButtonGroup fullWidth={true}>
              <Button
                className={classes.field}
                color="primary"
                fullWidth={true}
                onClick={() => setIsFileBrowserOpen(true)}
                size="small"
                variant="contained"
              >
                Upload Logo
              </Button>
              <Button
                className={classes.field}
                color="grey"
                fullWidth={true}
                onClick={onRemoveLogoFileId}
                size="small"
                variant="contained"
              >
                remove logo
              </Button>
            </ButtonGroup>
          </FormControl>

          <TextField
            fullWidth
            id="logoWidth"
            label="Logo Width"
            margin="normal"
            type="text"
            helperText="Accepts CSS numbers like 14px, 1.2em, etc"
            {...field(`custom.logo.width`, {defaultValue: "auto"})}
          />

          <TextField
            fullWidth
            id="logoHeight"
            label="Logo Height"
            margin="normal"
            type="text"
            helperText="Accepts CSS numbers like 14px, 1.2em, etc"
            {...field(`custom.logo.height`, {defaultValue: "auto"})}
          />
        </Box>
      </Grid>

      {isFileBrowserOpen && (
        <FileBrowser
          isOpen={isFileBrowserOpen}
          isImageUrlsDisabled={true}
          onClose={() => setIsFileBrowserOpen(false)}
          onSelect={onSetLogo}
          types={["image/png", "image/gif", "image/jpeg"]}
        />
      )}
    </Grid>
  )
}

LogoSettings.propTypes = {
  field: func.isRequired,
  logoFileId: string,
  template: object,
  muiTheme: object.isRequired,
  onChangeLogoFileId: func.isRequired,
  onRemoveLogoFileId: func.isRequired,
}

const useStyles = makeStyles({
  noFile: {
    backgroundColor: "lightgray",
    borderRadius: 3,
    padding: 20,
    paddingBottom: "75%",
  },
})

export default connect(({template}) => ({template}))(brandingSettings(LogoSettings))
