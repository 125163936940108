import {Badge, Card, CardContent} from "@mui/material"
import Box from "@mui/material/Box"
import {bool, func, oneOf, shape, string} from "prop-types"
import {FaExclamationTriangle} from "react-icons/fa"
import {MdCheck as SelectedIcon} from "react-icons/md"

import ContentLibraryImage from "components/content-library/content-library-image"
import SmsBubble from "components/content-library/sms-bubble"

const LibraryItem = ({onSelect, isSelected, item}) => {
  const {bodyPreview, id, type, contentName, teamId, hasPersonalization} = item

  const badgeVisibility = hasPersonalization ? false : !isSelected

  const badgeColor = hasPersonalization ? (isSelected ? "primary" : "secondary") : "primary"

  const badgeContent = () => {
    if (isSelected) {
      return <SelectedIcon size={8} />
    } else if (hasPersonalization) {
      return <FaExclamationTriangle size={12} color="yellow" />
    } else {
      return <SelectedIcon size={8} />
    }
  }
  return (
    <Badge
      badgeContent={badgeContent()}
      color={badgeColor}
      component="figure"
      data-testid="message-library-item"
      invisible={badgeVisibility}
      sx={{margin: 0}}
    >
      <Card
        onClick={() => onSelect(item)}
        sx={theme => ({
          flex: 1,
          backgroundColor: theme.palette.background.default,
          cursor: "pointer",
          boxShadow: "none",
          height: 200,
          "& p": {
            fontSize: 14,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            color: theme.palette.text.hint,
          },
          "& > :last-child": {
            paddingBottom: 0,
          },
        })}
        title={contentName}
      >
        <CardContent sx={{padding: 0}}>
          {type === "sms" && id !== "new" ? (
            <SmsBubble text={bodyPreview} />
          ) : (
            <ContentLibraryImage contentType={type} id={id} teamId={teamId || ""} />
          )}
          <Box component="figcaption">{contentName}</Box>
        </CardContent>
      </Card>
    </Badge>
  )
}

LibraryItem.propTypes = {
  isSelected: bool,
  item: shape({
    id: string.isRequired,
    teamId: string,
    contentName: string.isRequired,
    type: oneOf(["sms", "email"]),
  }),
  onSelect: func,
}

export default LibraryItem
