import {IconButton} from "@mui/material"
import {array, bool, func, object, string} from "prop-types"
import {MdMenu as MenuIcon} from "react-icons/md"

import Progress from "../progress/progress"
import HeaderLogo from "./header-logo"
import {journeyContext} from "./journey-context"

const JourneyHeader = ({
  analyticsCache,
  themeClasses,
  logoFileId,
  openMobileMenu,
  showMobileMenuIcon,
  showProgressMeter,
  teamName,
  reward,
  pages,
}) => (
  <header className={`journey-header ${themeClasses.header}`}>
    {showMobileMenuIcon ? (
      <IconButton
        aria-label="Open Menu"
        className={themeClasses.mobileMenuButton}
        onClick={openMobileMenu}
        size="medium"
      >
        <MenuIcon aria-hidden="true" />
      </IconButton>
    ) : (
      <div style={{height: 48, width: 48}} />
    )}
    {logoFileId && (
      <HeaderLogo
        alt={`${teamName} logo`}
        className={themeClasses.headerLogo}
        src={`${process.env.REACT_APP_API_URL}/files/${logoFileId}?variant=large`}
      />
    )}
    <br />
    {showProgressMeter && (
      <div style={{width: 100, height: 100, position: "absolute", bottom: -12, right: 19}}>
        <Progress
          analyticsCache={analyticsCache}
          pages={pages}
          reward={!!reward ? `$${reward}` : `%%`}
          subtitle={!!reward ? "Cash Bonus" : "Completed"}
        />
      </div>
    )}
  </header>
)

JourneyHeader.defaultProps = {
  showMobileMenuIcon: false,
  showProgressMeter: false,
}

JourneyHeader.propTypes = {
  analyticsCache: object,
  logoFileId: string,
  openMobileMenu: func,
  pages: array,
  reward: string,
  showMobileMenuIcon: bool,
  showProgressMeter: bool,
  teamName: string,
  themeClasses: object.isRequired,
}

export default journeyContext(JourneyHeader)
