import {Badge, Box, Card, CardContent, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {arrayOf, bool, func, object, shape, string} from "prop-types"
import {useEffect, useState} from "react"
import {MdCheck as SelectedIcon} from "react-icons/md"

import {fetchPages} from "lib/api"

import ContentLibraryImage from "./content-library-image"
import {filterItems} from "./helpers"

const usePageItemStyles = makeStyles(theme => ({
  cardRoot: {
    backgroundColor: theme.palette.background.default,
    cursor: "pointer",
    boxShadow: "none",
    height: 200,
    "& p": {
      fontSize: 14,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      color: theme.palette.text.hint,
    },
    "& > :last-child": {
      paddingBottom: 0,
    },
  },
  cardContent: {
    padding: 0,
  },
}))

const PageItem = ({page, onClick, isSelected}) => {
  const {id, teamId, contentName} = page
  const classes = usePageItemStyles()

  return (
    <Badge badgeContent={<SelectedIcon size={8} />} color="primary" invisible={!isSelected}>
      <Card classes={{root: classes.cardRoot}} onClick={onClick} title={contentName}>
        <CardContent className={classes.cardContent}>
          <ContentLibraryImage contentType="page" id={id} teamId={teamId || ""} />
          <Typography>{contentName}</Typography>
        </CardContent>
      </Card>
    </Badge>
  )
}

PageItem.propTypes = {
  isSelected: bool.isRequired,
  onClick: func.isRequired,
  page: object.isRequired,
}

const usePagesListStyles = makeStyles(theme => ({
  content: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    height: 550,
    display: "grid",
    gridColumnGap: theme.spacing(6),
    gridRowGap: theme.spacing(1),
    msGridRowGap: theme.spacing(1),
    fallbacks: [{display: "-ms-grid"}],
    gridTemplateColumns: "repeat(auto-fill, 150px)",
    msGridTemplateColumns: "repeat(auto-fill, 150px)",
    background: theme.palette.background.default,
  },
}))

export const newPageItem = {id: "new", contentName: "New Page"}

const PagesList = ({
  selectedPage,
  searchFilter,
  onChange,
  className,
  objectiveId,
  excludedPageIds = [],
}) => {
  const classes = usePagesListStyles()
  const [pages, setPages] = useState([])

  useEffect(() => {
    fetchPages({objective: objectiveId || null, isArchived: false}).then(pagesForObjective => {
      setPages([newPageItem, ...pagesForObjective])
    })
  }, [objectiveId])

  const togglePage = page => () => {
    selectedPage?.id === page.id ? onChange(null) : onChange(page)
  }

  return (
    <Box className={cx(classes.content, className)}>
      {filterItems(pages, searchFilter)
        .filter(page => !excludedPageIds.includes(page.id))
        .map(page => (
          <PageItem
            isSelected={page.id === selectedPage?.id}
            key={page.id}
            onClick={togglePage(page)}
            page={page}
          />
        ))}
    </Box>
  )
}

PagesList.propTypes = {
  className: string,
  excludedPageIds: arrayOf(string),
  objectiveId: string,
  onChange: func.isRequired,
  searchFilter: string,
  selectedPage: shape({
    id: string,
  }),
}

export default PagesList
