import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {bool, node, object, string} from "prop-types"

const Padded = ({classes, className, unbounded, verticalOnly, ...props}) => (
  <div
    className={cx(classes.padded, className, {
      [classes.unbounded]: unbounded,
      [classes.verticalOnly]: verticalOnly,
    })}
    {...props}
  />
)

Padded.propTypes = {
  children: node.isRequired,
  className: string,
  classes: object.isRequired,
  unbounded: bool,
  verticalOnly: bool,
}

export default withStyles(theme => ({
  padded: {
    padding: 32,
    maxWidth: theme.breakpoints.values.xl,
    margin: "auto",
  },
  verticalOnly: {
    padding: "32px 0",
  },
  unbounded: {
    maxWidth: "none",
  },
}))(Padded)
