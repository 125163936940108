import {Badge, Link, Tooltip} from "@mui/material"
import {bool, func, node, object, string} from "prop-types"
import {useEffect, useRef, useState} from "react"
import {FaExclamation as WarningIcon} from "react-icons/fa"
import {connect} from "react-redux"

import AppendPluginMenu from "components/content-block-editor/append-plugin-menu"
import SortableDragHandle from "components/content-block-editor/drag-handle"
import UploadedImage from "components/uploaded-image/uploaded-image"

import {templateMessageConsumer} from "contexts/template-message-context"
import {templatePageContext} from "contexts/template-page-context"
import {analytical} from "lib/hooks/use-analytics"

import {journeyContext} from "../../../journeys/journey-context"
import {contentBlockEditorConsumer} from "../../content-block-editor-context"
import {contentBlockPropType} from "../../content-block-editor-prop-types"
import {getCtaLinkErrorForBlock} from "../cta/cta-readonly"

const getSizing = (h, w) => {
  const height = h ? {height: `${h}px`} : {}
  const width = w ? {width: `${w}px`} : {}

  return {...height, ...width}
}

const getResizeBehavior = resizeBehavior => {
  switch (resizeBehavior) {
    case "cover-vertical":
      return {objectFit: "cover", height: "100%"}
    case "cover-horizontal":
      return {objectFit: "cover", width: "100%"}
    case "stretch":
      return {height: "100%", width: "100%"}
    default:
      return {maxHeight: "100%", maxWidth: "100%"}
  }
}

const getVariant = (size, width, automaticSize) => {
  if (size !== "automatic") return size

  if (!width) return automaticSize

  if (width > 800) return "large"
  else if (width > 400) return "medium"
  else if (width > 250) return "small"
  else return "preview"
}

const getUrl = ({id, url, size, width}, automaticSize) => {
  if (!id) return url

  const variant = getVariant(size || "automatic", width, automaticSize)

  return `${process.env.REACT_APP_API_URL}/files/${id}?variant=${variant}`
}

const _ImageLink = ({
  children,
  content_variables,
  isEditMode,
  isPreviewMode,
  onNavigateToPage,
  contentBlock,
  contentBlock: {
    contentContainerId,
    pageId,
    id: contentBlockId,
    data: {linksTo, linkUrl},
    slug,
  },
  template,
  track,
}) => {
  if (!linksTo || linksTo === "no-link") return children

  const linkError = getCtaLinkErrorForBlock({
    type: "image",
    contentBlock,
    content_variables,
    isEditMode,
    template,
  })

  const onClick = event => {
    track("cta_clicked", {
      widgetSlug: slug,
      // We'll have to rethink this if we ever allow CTAs in footers
      pageId,
      contentContainerId,
      contentBlockId,
    })

    if (linksTo === "journey-page") {
      event.preventDefault()

      const pageSlug = content_variables?.[slug]
      const isPageInTemplate = !!template?.templatePages?.find(
        ({page}) => page.newSlug === pageSlug
      )

      if (pageSlug && isPageInTemplate)
        onNavigateToPage(isPreviewMode ? `${pageSlug}#preview` : pageSlug)
    }
  }

  return (
    <>
      <Link
        onClick={onClick}
        href={linksTo === "journey-page" ? null : linkUrl}
        style={{cursor: "pointer", pointerEvents: isEditMode ? "none" : "auto"}}
        target="_blank"
      >
        {children}
      </Link>
      {linkError && (
        <Tooltip style={{verticalAlign: "top"}} title={linkError}>
          <Badge badgeContent={<WarningIcon size={10} />} color="error" />
        </Tooltip>
      )}
    </>
  )
}

_ImageLink.propTypes = {
  children: node,
  content_variables: object,
  contentBlock: contentBlockPropType,
  isEditMode: bool,
  isPreviewMode: bool,
  onNavigateToPage: func,
  template: object,
  track: func,
}

const ImageLink = connect(({template}) => ({template}))(
  journeyContext(templatePageContext(templateMessageConsumer(analytical(_ImageLink))))
)

export const ImageReadOnly = ({
  className,
  contentBlock,
  customCss,
  contentRef,
  isEditMode,
  isPreviewMode,
  onClick,
  themeClasses,
  style,
}) => {
  const ref = useRef(null)
  const {data} = contentBlock
  const {height, width, alt, resizeBehavior} = data
  const [automaticSize, setAutomaticSize] = useState("")

  // Add observer so we can automatically load the appropriate image size for the desired render size
  useEffect(() => {
    const element = ref?.current

    if (!element) return

    const observer = new ResizeObserver(() => {
      const {width} = ref.current.getBoundingClientRect()
      const variant = getVariant("automatic", width)

      setAutomaticSize(variant)
    })

    observer.observe(element)
    return () => observer.disconnect()
  }, [])

  return (
    <div
      className={className}
      onClick={onClick}
      style={style}
      ref={ref}
      data-testid="image-widget-wrapper"
    >
      <SortableDragHandle />
      <AppendPluginMenu contentBlock={contentBlock} />
      <style>{customCss}</style>
      <ImageLink contentBlock={contentBlock} isEditMode={isEditMode} isPreviewMode={isPreviewMode}>
        <UploadedImage
          alt={alt || ""}
          className={themeClasses?.image}
          ref={contentRef}
          src={getUrl(data, automaticSize)}
          style={{
            ...getSizing(height, width),
            ...getResizeBehavior(resizeBehavior),
            borderRadius: style?.borderRadius,
          }}
        />
      </ImageLink>
    </div>
  )
}

ImageReadOnly.propTypes = {
  className: string,
  contentBlock: contentBlockPropType.isRequired,
  customCss: string,
  contentRef: object,
  isEditMode: bool,
  isPreviewMode: bool,
  onClick: func,
  themeClasses: object,
  style: object,
}

export default contentBlockEditorConsumer(journeyContext(ImageReadOnly))
