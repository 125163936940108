import {Grid, Typography} from "@mui/material"
import {object} from "prop-types"
import {useEffect, useState} from "react"

import {fetchInsightSecurePersonalization} from "lib/api"

import {useInsights} from "./insights-context"
import {INSIGHTS_COLORS} from "./insights-helpers"
import SecurePersonalizationChart from "./secure-personalization-chart"

let colors = [...INSIGHTS_COLORS]
colors.unshift(INSIGHTS_COLORS[INSIGHTS_COLORS.length - 1])

const SecurePersonalization = ({classes}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const {
    insights: {secure_personalization},
    addInsightsCache,
    templateId,
    filter,
  } = useInsights()
  const securePersonalizationData = secure_personalization?.value.data || []

  useEffect(() => {
    fetchInsightSecurePersonalization(templateId, filter)
      .then(addInsightsCache)
      .finally(() => setIsLoaded(true))
  }, [addInsightsCache, templateId, filter])

  if (!isLoaded) return null

  return (
    <>
      {securePersonalizationData && securePersonalizationData.length > 0 ? (
        <>
          <Typography className={classes.header} variant="h4">
            Secure Personalization
          </Typography>
          <Grid container spacing={2} justifyContent={"flex-start"}>
            {securePersonalizationData.map((page, index) =>
              page.blocks.map(contentBlock => (
                <Grid
                  item={true}
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  data-testid="secure-personalization-chart"
                  key={contentBlock.id}
                >
                  <Typography className={classes.subheader} variant="h6">
                    {page.pageNavigationTitle}
                  </Typography>
                  <SecurePersonalizationChart
                    securePersonalizationData={contentBlock.result || []}
                    totalContacts={contentBlock.totalContacts || 0}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </>
      ) : null}
    </>
  )
}

SecurePersonalization.propTypes = {
  classes: object.isRequired,
}

export default SecurePersonalization
