import {Button, FormControl, InputLabel, ListItemText, MenuItem, TextField} from "@mui/material"
import {makeStyles} from "@mui/styles"
import {func, object} from "prop-types"
import {useState} from "react"
import {FaCamera as CameraIcon} from "react-icons/fa"

import CtaLinkSettings from "components/content-block-editor/cta-link-settings"
import DOSelect from "components/do-select/do-select"
import {drawerWidth} from "components/templates/template-helpers"
import UploadedImage from "components/uploaded-image/uploaded-image"

import {validDomainAndTld} from "lib/field-validations"
import humanize from "lib/string/humanize"
import {formatUrl} from "lib/string/url-helpers"

import {templatePageContext} from "../../../../contexts/template-page-context"
import useForm from "../../../../lib/hooks/use-form"
import loadImage from "../../../../lib/image/load-image"
import FileBrowser from "../../../file-browser/file-browser"
import {contentBlockPropType} from "../../content-block-editor-prop-types"
import FormActions from "../form-actions"

const isEmpty = value => value.length === 0
const isUploadedByImageURL = ({id}) => !id?.value

const getResizeBehaviorLabel = resizeBehavior => {
  switch (resizeBehavior) {
    case "cover-horizontal":
      return "Fill horizontal space"
    case "cover-vertical":
      return "Fill vertical space"
    default:
      return humanize(resizeBehavior)
  }
}

const ImageSettings = ({
  contentBlock,
  content_variables: _content_variables,
  initialValues,
  onSubmit,
  setContentVariables: onUpdateContentVariables,
}) => {
  const [content_variables, setContentVariables] = useState(_content_variables)
  const [isImageURLError, setImageURLError] = useState(false)
  const [isFileBrowserOpen, setFileBrowserOpen] = useState(false)

  const {change, field, handleSubmit, inputs, resetForm} = useForm({
    initialValues,
    // This should probably be linkUrl, as in the other case
    parse: {url: formatUrl},
    onSubmit: settings => {
      if (onUpdateContentVariables)
        return onUpdateContentVariables(content_variables).then(() => onSubmit(settings))
      else return onSubmit(settings)
    },
    validators: {linkUrl: [validDomainAndTld]},
  })

  const classes = useStyles()

  const getUrl = data =>
    data.id ? `${process.env.REACT_APP_API_URL}/files/${data.id}?variant=small` : data.url

  const onOpenFileBrowser = () => setFileBrowserOpen(true)
  const onCloseFileBrowser = () => setFileBrowserOpen(false)
  const onSelectFile = file => {
    const url = getUrl(file)

    loadImage(url).then(({target: {naturalWidth, naturalHeight}}) => {
      change("naturalWidth", naturalWidth)
      change("naturalHeight", naturalHeight)
      change("id", file.id)
      change("mimetype", file.type)
      change("type", "image")
      change("url", url)
    })

    onCloseFileBrowser()
  }

  const onImageURL = e => {
    const url = e.target.value

    loadImage(url)
      .then(() => setImageURLError(false))
      .catch(() => setImageURLError(!isEmpty(url)))
  }

  const handleResetForm = () => {
    setContentVariables(_content_variables)
    resetForm()
  }

  const resizeBehavior = field("resizeBehavior", {defaultValue: "default", exclude: ["helperText"]})

  const heightLocked = ["stretch", "cover-vertical"].includes(resizeBehavior.value)
  const widthLocked = ["stretch", "cover-horizontal"].includes(resizeBehavior.value)

  return (
    <form aria-label="Image Settings" className={classes.root} onSubmit={handleSubmit}>
      <InputLabel className={classes.imageLabel} shrink={true}>
        Image
      </InputLabel>
      <UploadedImage
        alt="preview"
        src={getUrl({url: inputs?.url?.value, id: inputs?.id?.value})}
        className={classes.preview}
      />
      {isUploadedByImageURL(inputs) && (
        <>
          <TextField
            error={isImageURLError}
            fullWidth={true}
            helperText={isImageURLError ? "Invalid image url" : ""}
            label="Image URL"
            {...field("url", {onChange: onImageURL})}
          />
        </>
      )}
      <FormControl margin="normal" fullWidth={true}>
        <Button
          className={`${classes.changeImageUrlButton} ${classes.centerAlignButton}`}
          onClick={onOpenFileBrowser}
        >
          Change Image
          <CameraIcon className={classes.cameraIcon} size={20} />
        </Button>
      </FormControl>
      <CtaLinkSettings
        change={change}
        field={field}
        contentBlock={contentBlock}
        content_variables={content_variables}
        onSetContentVariable={value =>
          setContentVariables(state => ({...state, [contentBlock.slug]: value}))
        }
        showNoLinkOption={true}
      />
      <TextField
        fullWidth={true}
        margin="normal"
        id="image-description"
        label="Description"
        helperText="for journeyers using accessibility assistance"
        {...field("alt")}
      />
      {inputs?.id?.value ? (
        <FormControl fullWidth={true} margin="normal">
          <InputLabel htmlFor={`image-${contentBlock.slug}-size`} shrink={true}>
            Image File Size
          </InputLabel>
          <DOSelect
            className={classes.field}
            id={`image-${contentBlock.slug}-size`}
            {...field("size", {defaultValue: "automatic", exclude: ["helperText"]})}
            renderValue={humanize}
          >
            <MenuItem classes={{root: classes.menuItem}} value="automatic">
              <ListItemText
                classes={{secondary: classes.listItemTextSecondary}}
                primary="Automatic"
                secondary="We'll automatically figure out the most appropriate size to load based on screen size and display area."
              />
            </MenuItem>
            <MenuItem classes={{root: classes.menuItem}} value="preview">
              <ListItemText
                classes={{secondary: classes.listItemTextSecondary}}
                primary="Extra Small"
                secondary="Use this size if you want to force a very small version of the image to be
                    loaded regardless of the device. If you display the image at a size larger than
                    250px it could look blocky or pixelated."
              />
            </MenuItem>
            <MenuItem classes={{root: classes.menuItem}} value="small">
              <ListItemText
                classes={{secondary: classes.listItemTextSecondary}}
                primary="Small"
                secondary="Use this size if you want to force a small version of the image to be loaded
                    regardless of the device. If you display the image at a size larger than 400px
                    it could look blocky or pixelated."
              />
            </MenuItem>
            <MenuItem classes={{root: classes.menuItem}} value="medium">
              <ListItemText
                classes={{secondary: classes.listItemTextSecondary}}
                primary="Medium"
                secondary="Use this size if you want to force a medium version of the image to be loaded
                    regardless of the device. If you display the image at a size larger than 800px
                    it could look blocky or pixelated."
              />
            </MenuItem>
            <MenuItem classes={{root: classes.menuItem}} value="large">
              <ListItemText
                classes={{secondary: classes.listItemTextSecondary}}
                primary="Large"
                secondary="Use this size if you want to force the highest resolution version of the image
                    to be loaded regardless of the device."
              />
            </MenuItem>
          </DOSelect>
        </FormControl>
      ) : (
        <TextField
          fullWidth={true}
          label="Image File Size"
          margin="normal"
          disabled={true}
          value="Not controlled"
          helperText={
            <>
              This image is hosted outside of Digital Onboarding. To control the file size of the
              image, use the <b>Change Image</b> button above to upload it to Digital Onboarding.
            </>
          }
        />
      )}
      <FormControl fullWidth={true} margin="normal">
        <InputLabel htmlFor={`image-${contentBlock.slug}-resize-behavior`}>
          Resize Behavior
        </InputLabel>
        <DOSelect
          className={classes.field}
          id={`image-${contentBlock.slug}-resize-behavior`}
          {...resizeBehavior}
          renderValue={getResizeBehaviorLabel}
        >
          <MenuItem classes={{root: classes.menuItem}} value="default">
            <ListItemText
              classes={{secondary: classes.listItemTextSecondary}}
              primary={getResizeBehaviorLabel("default")}
              secondary="The image will be constrained to the available space in your layout but will not grow larger than its natural dimensions."
            />
          </MenuItem>
          <MenuItem classes={{root: classes.menuItem}} value="cover-horizontal">
            <ListItemText
              classes={{secondary: classes.listItemTextSecondary}}
              primary={getResizeBehaviorLabel("cover-horizontal")}
              secondary="Proportionally scale the image up and down to fill the horizontal space available to this content block. The top and bottom of the image may be clipped to make it fit the space."
            />
          </MenuItem>
          <MenuItem classes={{root: classes.menuItem}} value="cover-vertical">
            <ListItemText
              classes={{secondary: classes.listItemTextSecondary}}
              primary={getResizeBehaviorLabel("cover-vertical")}
              secondary="Proportionally scale the image up and down to fill the vertical space available to this content block. The left and right edges of the image may be clipped to make it fit the space."
            />
          </MenuItem>
          <MenuItem classes={{root: classes.menuItem}} value="stretch">
            <ListItemText
              classes={{secondary: classes.listItemTextSecondary}}
              primary={getResizeBehaviorLabel("stretch")}
              secondary="Scale the image up and down to fill all space available to this content block. The image will be distorted if necessary."
            />
          </MenuItem>
        </DOSelect>
      </FormControl>
      {heightLocked ? (
        <TextField
          fullWidth={true}
          label="Pixel Height"
          margin="normal"
          disabled={true}
          value="100%"
          helperText="This value is set by the selected Resize Behavior"
        />
      ) : (
        <TextField
          fullWidth={true}
          label="Pixel Height"
          margin="normal"
          placeholder="Auto"
          type="number"
          {...field("height")}
        />
      )}
      {widthLocked ? (
        <TextField
          fullWidth={true}
          label="Pixel Width"
          margin="normal"
          disabled={true}
          value="100%"
          helperText="This value is set by the selected Resize Behavior"
        />
      ) : (
        <TextField
          fullWidth={true}
          label="Pixel Width"
          margin="normal"
          placeholder="Auto"
          type="number"
          {...field("width")}
        />
      )}
      <FormControl margin="normal" fullWidth={true}>
        <FormActions resetForm={handleResetForm} />
      </FormControl>

      {isFileBrowserOpen && (
        <FileBrowser
          isOpen={isFileBrowserOpen}
          onClose={onCloseFileBrowser}
          onSelect={onSelectFile}
          types={["image/png", "image/gif", "image/jpeg"]}
        />
      )}
    </form>
  )
}

ImageSettings.propTypes = {
  contentBlock: contentBlockPropType,
  content_variables: object,
  initialValues: object,
  onSubmit: func.isRequired,
  setContentVariables: func,
}

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "100%",
  },
  field: {
    margin: "10px 0",
  },
  menuItem: {
    maxWidth: `calc(${drawerWidth}px - ${theme.spacing(3)})`,
    whiteSpace: "normal",
  },
  cameraIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  changeImageUrlButton: {
    height: "100%",
  },
  centerAlignButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  preview: {
    maxWidth: "100%",
    margin: "0 auto",
    display: "block",
  },
  imageLabel: {
    marginTop: theme.spacing(),
  },
}))

export default templatePageContext(ImageSettings)
