import {Box, CircularProgress, TextField, Typography} from "@mui/material"
import {arrayOf, shape, string} from "prop-types"
import {useEffect, useState} from "react"

import {filterItems, humanizeType} from "components/content-library/helpers"

import {fetchContentLibrary} from "lib/api"

import LibraryItem from "./library-item"

const PERSONALIZATION_WARNING_TEXT =
  "Emails with personalization cannot be sent as part of a mass-messaging campaign. You will need to remove any personalization before the campaign can be sent."

const ContentLibrary = ({selectedMessages = [], onChange}) => {
  const [massMessagingLibrary, setMassMessagingLibrary] = useState({})
  const [filter, setFilter] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    fetchContentLibrary({
      contentTypes: ["email"],
      isArchived: false,
      objective: null, // not sure plan here, should this just be objective: mass-messaging and no campaignType?
      campaignType: "mass-messaging",
    }).then(libraryItems => {
      setIsLoading(false)
      setMassMessagingLibrary(generateMassMessagingLibrary(libraryItems))
    })
  }, [])

  const onChangeFilter = ({target: {value}}) => {
    setFilter(value)
  }

  const onSelectItemMassMessaging = item => {
    // Clear if selecting the one selected message, otherwise only the one message
    onChange(!!selectedMessages.find(m => m.id === item.id) ? [] : [item])
  }

  const generateMassMessagingLibrary = items => {
    const itemsWithPersonalization = []
    const itemsWithoutPersonalization = []
    items.forEach(item => {
      if (item.hasPersonalization) return itemsWithPersonalization.push(item)
      itemsWithoutPersonalization.push(item)
    })
    return {
      email: itemsWithoutPersonalization,
      emailWithPersonalization: itemsWithPersonalization,
    }
  }

  return [
    <Box
      key="filters"
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <TextField
        onChange={onChangeFilter}
        placeholder="Search..."
        sx={theme => ({
          flex: 1,
          marginRight: theme.spacing(2),
          maxWidth: 400,
        })}
      />
    </Box>,

    isLoading ? (
      <Box
        key="loading"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    ) : (
      Object.entries(massMessagingLibrary).map(([category, items]) => {
        return (
          <Box component="section" key={category}>
            <Typography>{humanizeType(category)}</Typography>
            {category === "emailWithPersonalization" && (
              <Typography variant="p2" color="gray" sx={{paddingBottom: "10px"}}>
                {PERSONALIZATION_WARNING_TEXT}
              </Typography>
            )}
            <Box
              sx={theme => ({
                display: "grid",
                gridColumnGap: theme.spacing(6),
                gridRowGap: theme.spacing(1),
                marginTop: theme.spacing(2),
                msGridRowGap: theme.spacing(1),
                fallbacks: [{display: "-ms-grid"}],
                gridTemplateColumns: "repeat(auto-fill, 150px)",
                msGridTemplateColumns: "repeat(auto-fill, 150px)",
              })}
            >
              {filterItems(items, filter.toLowerCase()).map(item => (
                <LibraryItem
                  isSelected={!!selectedMessages.find(m => m.id === item.id)}
                  item={item}
                  key={item.id}
                  onSelect={onSelectItemMassMessaging}
                />
              ))}
            </Box>
          </Box>
        )
      })
    ),
  ]
}

ContentLibrary.propTypes = {
  objective: shape({
    id: string,
    name: string,
  }),
  selectedMessages: arrayOf(
    shape({
      id: string,
    })
  ),
}

ContentLibrary.defaultProps = {
  contentType: "all",
}

export default ContentLibrary
