import {Button, TextField} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {bool, func, object} from "prop-types"
import {Component} from "react"

import {requiredField, validEmail} from "lib/field-validations"
import {formify} from "lib/hooks/use-form"

import Box from "../box/box"
import DocumentTitle from "../document-title/document-title"
import Padded from "../padded/padded"
import TitleBar from "../title-bar/title-bar"
import UserPermissionsFormGroup from "./user-permissions-form-group"

export class UserInviteForm extends Component {
  state = {
    selectedPermissions: ["templates:view", "templates:edit", "insights:view"],
  }

  onChangePermission = permissions => {
    this.setState({selectedPermissions: permissions})
  }

  handleSubmit = e => {
    e.preventDefault()

    const {inputs, onSubmit} = this.props

    if (Object.values(inputs).filter(({error}) => error).length > 0) return

    const data = {
      ...Object.values(inputs).reduce((acc, input) => ({...acc, [input.name]: input.value}), {}),
      permissions: this.state.selectedPermissions,
    }

    onSubmit(data)
  }

  render() {
    const {classes, field, invalid} = this.props
    const {selectedPermissions} = this.state

    return (
      <div>
        <DocumentTitle title="Invite a New Team Member" />
        <TitleBar
          backButtonAttrs={{to: "/admin/team", children: "Return to Team Dashboard"}}
          title="Invite a New Team Member"
        />

        <Padded className={classes.padded}>
          <Box>
            <form onSubmit={this.handleSubmit}>
              <TextField
                className={classes.field}
                fullWidth={true}
                label="First Name"
                {...field("nameFirst")}
              />
              <TextField
                className={classes.field}
                fullWidth={true}
                label="Last Name"
                {...field("nameLast")}
              />
              <TextField
                className={classes.field}
                fullWidth={true}
                label="Email"
                {...field("email")}
              />
              <UserPermissionsFormGroup
                gridSize="2x2"
                onChange={this.onChangePermission}
                selectedPermissions={selectedPermissions}
              />
              <div className={classes.formActions}>
                <Button color="primary" disabled={invalid} type="submit" variant="contained">
                  Invite
                </Button>
              </div>
            </form>
          </Box>
        </Padded>
      </div>
    )
  }
}

UserInviteForm.propTypes = {
  classes: object.isRequired,
  field: func.isRequired,
  handleSubmit: func.isRequired,
  inputs: object.isRequired,
  invalid: bool.isRequired,
  onSubmit: func.isRequired,
}

const styles = () => ({
  field: {
    margin: "10px 0",
  },
  helperText: {
    marginBottom: 10,
  },
  formActions: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 20,
  },
  padded: {
    maxWidth: 800,
    margin: "0 auto",
  },
})

const StyledUserInviteForm = withStyles(styles)(UserInviteForm)

export default formify({
  validators: {
    email: [requiredField, validEmail],
  },
})(StyledUserInviteForm)
