import {Box as MUIBox, Typography} from "@mui/material"
import Skeleton from "@mui/material/Skeleton"
import * as Sentry from "@sentry/browser"
import memoize from "lodash/memoize"
import range from "lodash/range"
import {Fragment, useEffect, useState} from "react"
import {useSelector} from "react-redux"

import {isUserPermitted} from "components/access-control/access-controlled"
import SkeletonLoader from "components/skeleton-loader/skeleton-loader"
import NotificationSettingForm from "components/teams/notifications/notification-setting-form"

import {fetchNotificationSettings, updateNotificationSettings} from "lib/api"
import humanize from "lib/string/humanize"

// Group and sort notifications by type
const arrangeNotifications = notifications => sortNotifications(groupNotifications(notifications))

const humanGrouping = dataGrouping => {
  switch (dataGrouping) {
    case "contact":
    case "objective":
    case "reward":
      return "contacts"
    case "batch":
    case "targeting":
      return "data"
    case "cta":
    case "enrollment":
    case "survey":
      return "widgets"
    default:
      // This category is only here for dev convenience so that we can see notifications that have not yet been grouped. We should always evaluate which of the three HUMAN categories should own a notification.
      Sentry.captureMessage(`An uncategorized notification grouping has escaped! ${dataGrouping}`)
      return "uncategorized"
  }
}

const description = notificationType =>
  ({
    "batch.completed":
      "This event is triggered when Digital Onboarding finishes processing a batch import.",
    "contact.created":
      "This event is triggered when a new contact is created in the system. This does not include contacts created from batches.",
    "contact.unsubscribed":
      "This event is triggered when a contact unsubscribes from email or sms.",
    "contact.updated":
      "This event is triggered when any data (except unsubscribing) is updated for an existing contact. This does not include contacts updated from batches.",
    "cta.clicked":
      "This event is triggered when a contact clicks a CTA in a journey, channel content, email, or sms.",
    "enrollment.accepted":
      "This event is triggered when a contact successfully completes an enrollment widget.",
    "enrollment.declined": "This event is triggered when a contact delines an enrollment widget.",
    "objective.completed": "This event is triggered when a contact completes any objective.",
    "reward.earned":
      "This event is triggered when a contact satisfies all steps required for a reward.",
    "survey.completed": "This event is triggered when a contact completes a survey widget.",
    "targeting.failed":
      "This event is triggered when an error occurs while targeting is run for a campaign.",
    "targeting.limit.exceeded":
      "This event is triggered when the targeting conditions on a campaign would pull in more contacts than you may have intended. Targeting will be paused on the campaign until the limit is raised.",
  }[notificationType])

/**
 *
 * Group by title
 *
 * Example:
 *
 *    groupNotifications([
 *     {type: "contact.created"},
 *     {type: "contact.updated}
 *    ])
 *
 *    {
 *     contact: [
 *      {type: "contact.created"},
 *      {type: "contact.updated"}
 *     ]
 *    }
 *
 */
const groupNotifications = notifications =>
  notifications.reduce((acc, notification) => {
    const [title] = notification.type.split(".")
    const humanTitle = humanGrouping(title)
    const priorNotifications = acc?.[humanTitle] ?? []
    return {
      ...acc,
      [humanTitle]: [...priorNotifications, notification],
    }
  }, {})

/**
 *
 * Sort by group title
 *
 * Example:
 *
 *    sortNotifications({
 *      enrollment: [{...}],
 *      contact: [{...}]
 *    })
 *
 *    [
 *      ["contact", [{...}]],
 *      ["enrollment", [{...}]]
 *    ]
 *
 */
const sortNotifications = notifications => {
  return Object.keys(notifications)
    .sort()
    .map(key => [key, notifications[key].sort((a, b) => a.type.localeCompare(b.type))])
}

const getSubtitle = notification => humanize(notification.type.split("."))

const getRandomFloat = (min, max, precision = 1) =>
  parseFloat((Math.random() * (max - min) + min).toFixed(precision))

// NB: Memoizing here to prevent height from re-randomizing itself on every re-render
const getPreCalculatedHeight = memoize((_rangeValue, height) => height * getRandomFloat(1, 2))

const NotificationSettingsSkeleton = () =>
  range(8).map(rangeValue => (
    <Fragment key={rangeValue}>
      <Typography sx={{mb: 1, width: 200}} variant="h5">
        <Skeleton sx={{height: 40}} variant="rectangular" />
      </Typography>
      <Skeleton
        sx={{height: getPreCalculatedHeight(rangeValue, 170), mb: 4}}
        variant="rectangular"
      />
    </Fragment>
  ))

const NotificationSettings = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [notifications, setNotifications] = useState([])

  const users = useSelector(({session}) => session.teamUsers)
  const team = useSelector(({session}) => session.team)

  const isWebhookUrlSet = !!team?.webhook
  const isAnyUserPermittedToReceiveNotifications = users.some(user =>
    isUserPermitted(user, "notifications:receive")
  )

  const onSubmit = notification => updateNotificationSettings(notification.id, notification)

  useEffect(() => {
    setIsLoaded(false)
    fetchNotificationSettings()
      .then(n => setNotifications(arrangeNotifications(n)))
      .finally(() => setIsLoaded(true))
  }, [])

  const renderNotificationSettingsBox = ([title, notifications]) => (
    <Fragment key={title}>
      <Typography
        sx={{mb: 2, borderBottom: 1, borderBottomColor: "background.darkened"}}
        variant="h5"
      >
        {title === "data" ? "Data & Processing" : humanize(title)}
      </Typography>
      <MUIBox sx={{mb: 12}}>{notifications.map(renderNotificationSettingsForm)}</MUIBox>
    </Fragment>
  )

  const renderNotificationSettingsForm = notification => (
    <MUIBox sx={{display: "flex", mb: 4}} key={notification.type}>
      <MUIBox sx={{width: 290}}>
        <Typography sx={{fontWeight: "medium", fontSize: "1rem"}} variant="h6">
          {getSubtitle(notification)}
        </Typography>
        <Typography sx={{color: "text.secondary"}}>{description(notification.type)}</Typography>
      </MUIBox>
      <MUIBox sx={{flex: 1, pl: 2}}>
        <NotificationSettingForm
          initialValues={notification}
          isWebhookUrlSet={isWebhookUrlSet}
          isAnyUserPermittedToReceiveNotifications={isAnyUserPermittedToReceiveNotifications}
          onSubmit={onSubmit}
          recipients={users}
        />
      </MUIBox>
    </MUIBox>
  )

  return (
    <>
      <Typography sx={{mt: 2, mb: 4}}>
        Tuning your notifications can keep you informed on the events you want to know about and
        help you react quickly and automatically to the actions your contacts are taking in your
        campaigns. You can select which events in the system trigger a notification to your webhook
        or emails to selected recipients using the controls below.
      </Typography>
      <SkeletonLoader loaded={isLoaded} Loader={NotificationSettingsSkeleton}>
        {notifications.map(renderNotificationSettingsBox)}
      </SkeletonLoader>
    </>
  )
}

export default NotificationSettings
