import {Box, TextField, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {arrayOf, func, object, shape, string} from "prop-types"
import {useState} from "react"

import PagesList from "../content-library/pages-list"

const setPage = payload => ({type: "PAGE_SET", payload})

const usePagesListStyles = makeStyles(theme => ({
  pagesList: {
    paddingLeft: 0,
    height: "auto",
  },
  searchField: {
    flex: 1,
    marginRight: theme.spacing(2),
    maxWidth: 400,
  },
  searchWrapper: {
    marginBottom: theme.spacing(2),
  },
}))

const AddPageStep = ({currentRow: {objective, page}, otherRows, dispatch}) => {
  const [filter, setFilter] = useState("")

  const onChangeFilter = ({target: {value}}) => {
    setFilter(value)
  }

  const classes = usePagesListStyles()

  return (
    <>
      <Box key="title" my={1}>
        <Typography>
          Showing pages related to <i>{objective?.name ?? "no objective"}</i>
        </Typography>
      </Box>
      <Box className={classes.searchWrapper} key="search-bar">
        <TextField
          className={classes.searchField}
          onChange={onChangeFilter}
          placeholder="Search..."
        />
      </Box>
      <PagesList
        className={classes.pagesList}
        excludedPageIds={otherRows.map(row => row.page?.id).filter(id => !id?.includes("new"))}
        objectiveId={objective?.id}
        onChange={p => dispatch(setPage(p))}
        searchFilter={filter.toLowerCase()}
        selectedPage={page}
      />
    </>
  )
}

AddPageStep.propTypes = {
  currentRow: shape({
    objective: shape({id: string}),
    page: object,
  }),
  dispatch: func.isRequired,
  otherRows: arrayOf(
    shape({
      page: shape({
        id: string,
      }),
    })
  ),
}

export default AddPageStep
