import {Grid, Typography} from "@mui/material"
import Skeleton from "@mui/material/Skeleton"
import {object} from "prop-types"
import {useEffect, useState} from "react"

import {useInsights} from "components/template-insights/insights-context"
import {INSIGHTS_COLORS} from "components/template-insights/insights-helpers"
import StatsPie from "components/template-insights/stats-pie"

import {fetchInsightOptInWidget} from "lib/api"
import pluralize from "lib/string/pluralize"

let colors = [...INSIGHTS_COLORS]
colors.unshift(INSIGHTS_COLORS[INSIGHTS_COLORS.length - 1])

const OptInWidget = ({classes}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const {
    insights: {opt_in_widget},
    addInsightsCache,
    templateId,
    filter,
  } = useInsights()

  const stats = opt_in_widget?.value?.stats ?? []
  const total = opt_in_widget?.value?.totalJourneys ?? 0
  const totalLabel = pluralize("journey", total)

  useEffect(() => {
    fetchInsightOptInWidget(templateId, filter)
      .then(addInsightsCache)
      .finally(() => setIsLoaded(true))
  }, [addInsightsCache, templateId, filter])

  if (!isLoaded) return <Skeleton variant="circular" width={150} height={150} />

  return (
    <>
      <Typography className={classes.subheader} variant="h6">
        Opt-in
      </Typography>
      <Grid container spacing={2} justifyContent={"flex-start"}>
        <Grid item={true} xs={12} sm={12} md={4} lg={4} xl={4}>
          {total > 0 ? (
            <StatsPie colors={colors} data={stats} total={total} totalLabel={totalLabel} />
          ) : (
            <div>No data available</div>
          )}
        </Grid>
      </Grid>
    </>
  )
}

OptInWidget.propTypes = {
  classes: object.isRequired,
}

export default OptInWidget
