import {TextField} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func} from "prop-types"
import {useState} from "react"

import {formify} from "lib/hooks/use-form"

import FormActions from "../form-actions"
import {embedToData} from "./video-helpers"

const VIDEO_ERRORS = {
  INVALID_URL: "Invalid url",
  UNSUPPORTED_SERVICE: "We currently only support YouTube, Vimeo and Wistia videos",
}

const VideoSettings = ({change, field, handleSubmit, resetForm}) => {
  const [errorText, setErrorText] = useState(null)
  const classes = useStyles()
  const rawEmbed = field("rawEmbed")

  const validateInput = inputValue => {
    const data = embedToData(inputValue)
    setErrorText(null)

    if (!data) {
      setErrorText(VIDEO_ERRORS.UNSUPPORTED_SERVICE)
      return
    }

    const {service, id} = data

    if (!service || !id) {
      setErrorText(VIDEO_ERRORS.INVALID_URL)
      return
    }

    change("service", service)
    change("id", id)
  }

  const onChange = event => {
    validateInput(event.target.value)
    rawEmbed.onChange(event)
  }

  return (
    <form className={classes.root} onSubmit={handleSubmit} aria-label="Video Settings">
      <TextField
        autoFocus={true}
        fullWidth={true}
        id={`video-embed-or-url`}
        label="URL or Embed Code"
        margin="normal"
        type="text"
        error={!!errorText}
        helperText={errorText}
        {...rawEmbed}
        onChange={onChange}
      />
      <FormActions resetForm={resetForm} buttonDisabled={!!errorText} />
    </form>
  )
}

VideoSettings.propTypes = {
  change: func.isRequired,
  field: func.isRequired,
  handleSubmit: func.isRequired,
  resetForm: func,
}

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}))

const VideoSettingsForm = formify()(VideoSettings)

export {VIDEO_ERRORS}
export default VideoSettingsForm
