import {Button, CircularProgress, Dialog, DialogContent, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {object} from "prop-types"
import {useEffect, useState} from "react"
import {MdAdd as AddIcon} from "react-icons/md"
import {connect} from "react-redux"

import {headTemplateJourneys} from "lib/api"

import {useTemplateContentContext} from "../../contexts/template-content-context"
import Box from "../box/box"
import ConfirmDialog from "../dialogs/confirm-dialog"
import MessageScheduler from "../message-scheduler/horizontal-message-scheduler"
import DOTable from "../table/table"
import AddPageToTemplateButton from "../templates/add-page-to-template-button"
import TemplateMessageForm from "../templates/template-message-form"
import MessageAddDialog from "./message-add-dialog"
import NurturingMessageRow from "./nurturing-message-row"
import ScheduledMessageRow from "./scheduled-message-row"
import TemplatePages from "./template-pages"

const TemplateContent = ({classes, currentUser}) => {
  const {
    template,
    templateId,
    editTemplateMessage,
    templateMessages,
    templateMessagesLoading,
    templateMessageToEdit,
    setTemplateMessageToEdit,
    removeTemplateMessage,
    completeEditTemplateMessage,
    makeMessageUnique,
    sendSampleMessage,
    fetchTemplateMessages,
    fetchTemplatePages,
    templatePages,
    setTemplateMessageToRemove,
    sortTemplateMessages,
    fetchTimelineMarkers,
    updateTemplateMessage,
    templateMessageToRemove,
    messageLoading,
  } = useTemplateContentContext()

  const massMessaging = template.type === "mass-messaging"

  const [messageSelectorOptions, setMessageSelectorOptions] = useState()

  const [totalJourneys, setTotalJourneys] = useState(0)

  const calcTotalJourneys = template => {
    headTemplateJourneys(template.id).then(([, fetchResponse]) => {
      const totalJourneys = parseInt(fetchResponse.headers.get("x-total-count"), 10)
      setTotalJourneys(totalJourneys)
    })
  }

  useEffect(() => {
    calcTotalJourneys(template)
  }, [template, totalJourneys])

  useEffect(() => {
    fetchTemplateMessages()
    fetchTemplatePages()
    fetchTimelineMarkers()
  }, [fetchTemplateMessages, fetchTemplatePages, fetchTimelineMarkers])

  return (
    <>
      {messageLoading && (
        <Dialog open={true}>
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        </Dialog>
      )}
      <MessageAddDialog
        messageSelectorOptions={messageSelectorOptions}
        setMessageSelectorOptions={setMessageSelectorOptions}
      />
      <ConfirmDialog
        content="Are you sure you want to remove this message from this campaign?"
        onClose={() => setTemplateMessageToRemove(null)}
        onConfirm={removeTemplateMessage}
        open={!!templateMessageToRemove}
      />
      {templateMessageToEdit && (
        <TemplateMessageForm
          currentUser={currentUser}
          dialogTitle={templateMessageToEdit.contentName}
          onClose={() => setTemplateMessageToEdit(null)}
          onDelete={() => removeTemplateMessage(templateMessageToEdit.id)}
          onMakeMessageUnique={templateMessageToEdit.id ? makeMessageUnique : null}
          onSendSampleMessage={sendSampleMessage}
          onSubmit={completeEditTemplateMessage}
          templateId={templateId}
          templateMessage={templateMessageToEdit}
          templatePages={templatePages}
          type={templateMessageToEdit.message.type}
        />
      )}
      <Box component="section" data-testid="template-content">
        <div className={classes.hideWhenSorting}>
          {templatePages?.length ? (
            <>
              <Typography className={classes.sectionHeader} gutterBottom={true} variant="h2">
                Pages
              </Typography>
              <TemplatePages key={templateId} />
            </>
          ) : null}
          <div className={classes.headerWrapper}>
            <Typography
              className={classes.sectionHeader}
              gutterBottom={true}
              style={{paddingTop: 20}}
              variant="h2"
            >
              Drip Messages
            </Typography>
            {templatePages?.length ? null : (
              <AddPageToTemplateButton template={template} templatePages={templatePages} />
            )}
          </div>
          {template?.id && <MessageScheduler template={template} />}
          {!!totalJourneys && (
            <Box my={2}>
              <Typography className={classes.errorText}>
                This template already has <b>{totalJourneys.toLocaleString()}</b> journeys. Adding,
                changing, moving, or removing pins will produce unexpected results.
              </Typography>
            </Box>
          )}

          <div className={classes.tableWrapper} data-testid="nurturing-messages">
            <DOTable
              headers={[
                {field: "archived", label: "", sortable: false},
                {field: "drag-handle", label: "", sortable: false},
                {field: "order", label: "Send Order", sortable: false},
                {field: "preview", label: "", sortable: false},
                {field: "contentName", label: "Title", sortable: false},
                {field: "type", label: "Type", sortable: false},
                {field: "ineligibilityCondition", label: "Don't Send if", sortable: false},
                {field: "objective", label: "Objective", sortable: false},
                {field: "edit", label: "", sortable: false},
              ]}
              isTableLoading={templateMessagesLoading}
              noResults="No messages have been added to this campaign"
              paginationEnabled={false}
              rows={templateMessages.filter(tm => tm.queue === "nurturing")}
              rowSortOptions={{
                onSortEnd({oldIndex, newIndex}) {
                  if (oldIndex === newIndex) return

                  sortTemplateMessages(oldIndex, newIndex)
                },
                helperClass: classes.sortingRow,
              }}
            >
              {(row, index, column) => (
                <NurturingMessageRow
                  column={column}
                  editTemplateMessage={editTemplateMessage} // SortableElement swallows the index
                  i={index}
                  index={index}
                  key={row.id}
                  row={row}
                  setTemplateMessageToRemove={setTemplateMessageToRemove}
                  templatePages={templatePages}
                  updateTemplateMessage={updateTemplateMessage}
                />
              )}
            </DOTable>
            <div className={classes.tableActions}>
              <Button
                color="primary"
                onClick={() => setMessageSelectorOptions({type: "sms", queue: "nurturing"})}
                variant="contained"
              >
                <AddIcon size={20} /> Add SMS
              </Button>
              <Button
                color="primary"
                onClick={() => setMessageSelectorOptions({type: "email", queue: "nurturing"})}
                variant="contained"
              >
                <AddIcon size={20} /> Add Email
              </Button>
            </div>
          </div>
        </div>

        <Typography
          className={cx(classes.sectionHeader, classes.scheduledMessagesHeader)}
          gutterBottom={true}
          variant="h2"
        >
          Scheduled Messages
        </Typography>
        <div className={classes.tableWrapper}>
          <DOTable
            headers={[
              {field: "archived", label: "", sortable: false},
              {field: "scheduledAt", label: "Sent/Sending", sortable: false},
              {field: "schedule", label: "", sortable: false},
              {field: "status", label: "Status", sortable: false},
              {field: "preview", label: "", sortable: false},
              {field: "contentName", label: "Title", sortable: false},
              {field: "type", label: "Type", sortable: false},
              {field: "ineligibilityCondition", label: "Don't Send if", sortable: false},
              {field: "objective", label: "Objective", sortable: false},
              {field: "edit", label: "", sortable: false},
            ]}
            isTableLoading={templateMessagesLoading}
            noResults="No scheduled messages have been added to this campaign"
            paginationEnabled={false}
            rows={templateMessages.filter(tm => tm.queue === "scheduled" || tm.queue === "draft")}
          >
            {(row, index, column) => (
              <ScheduledMessageRow
                column={column}
                editTemplateMessage={editTemplateMessage}
                key={row.id}
                row={row}
                setTemplateMessageToRemove={setTemplateMessageToRemove}
                templatePages={templatePages}
                updateTemplateMessage={updateTemplateMessage}
              />
            )}
          </DOTable>
          {massMessaging ? (
            <div className={classes.tableActions}>
              <Button
                color="primary"
                onClick={() => setMessageSelectorOptions({type: "sms", queue: "draft"})}
                variant="contained"
              >
                Send Now
              </Button>
            </div>
          ) : (
            <div className={classes.tableActions}>
              <Button
                color="primary"
                onClick={() => setMessageSelectorOptions({type: "sms", queue: "draft"})}
                variant="contained"
              >
                <AddIcon size={20} /> Add Scheduled SMS
              </Button>
              <Button
                color="primary"
                onClick={() => setMessageSelectorOptions({type: "email", queue: "draft"})}
                variant="contained"
              >
                <AddIcon size={20} /> Add Scheduled Email
              </Button>
            </div>
          )}
        </div>
      </Box>
    </>
  )
}

TemplateContent.propTypes = {
  classes: object.isRequired,
  currentUser: object,
}

const styles = theme => ({
  sectionHeader: {
    fontSize: theme.typography.fontSize * 2,
    marginBottom: theme.spacing(3),
  },
  scheduledMessagesHeader: {
    marginTop: theme.spacing(4),
  },
  headerWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  hideWhenSorting: {
    "& .isSorting .showOnHover": {
      visibility: "hidden !important",
    },
  },
  sortingRow: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[8],
    borderBottom: "none",
    display: "flex",
    width: "100%",
    "& td": {
      display: "none",
    },
    "& > td:nth-child(1)": {
      height: "100%",
      display: "flex",
      alignItems: "center",
      order: -2,
      justifySelf: "flex-start",
    },
    "& > td:nth-child(1) *": {
      visibility: "visible",
    },
    "& > td:nth-child(4)": {
      height: "100%",
      display: "flex",
      alignItems: "center",
      flex: 1,
    },
  },
  tableActions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    "& > button:first-child": {
      marginRight: theme.spacing(2),
    },
  },
  tableWrapper: {
    "& td:nth-child(2), & th:nth-child(2)": {
      paddingRight: theme.spacing(2),
    },
    "& td:nth-child(3), & th:nth-child(3)": {
      paddingRight: theme.spacing(2),
    },
    "& td:nth-child(4), & th:nth-child(4)": {
      paddingRight: 0,
    },
  },
})

const mapStateToProps = ({session}) => ({
  currentUser: session?.user,
})

export default withStyles(styles)(connect(mapStateToProps)(TemplateContent))
