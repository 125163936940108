import {Fab, Fade, Menu, Slide} from "@mui/material"
import {ThemeProvider} from "@mui/material/styles"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {bool, node} from "prop-types"
import {forwardRef, useRef, useState} from "react"
import {FaPlus as PlusIcon} from "react-icons/fa"
import {useDispatch} from "react-redux"

import {actions} from "components/content-block-editor/content-block-editor-reducer"
import {containerPlugins} from "components/content-block-editor/plugins"
import {provideObjectives, useObjectives} from "components/team-insights/objectives-context"

import doTheme from "themes/main"

import {useContentBlockEditor} from "./content-block-editor-context"
import {containerPropType} from "./content-block-editor-prop-types"

const SlideAndFade = forwardRef(({children, ...props}, ref) => (
  <Fade {...props} easing={{enter: "easeOut"}} ref={ref} timeout={350}>
    <div>
      <Slide {...props} direction={"up"} easing={{enter: "easeOut"}} timeout={350}>
        {children}
      </Slide>
    </div>
  </Fade>
))

SlideAndFade.propTypes = {
  children: node.isRequired,
  in: bool,
}

const PluginMenu = ({container, isEmpty}) => {
  const dispatch = useDispatch()

  const objectives = useObjectives()

  const [open, setOpen] = useState(false)

  const ref = useRef(null)

  const onTogglePluginMenu = () => setOpen(state => !state)

  const onClosePluginMenu = () => setOpen(false)

  const classes = useStyles()

  const {onAddContentBlock, isEmail: _isEmail} = useContentBlockEditor()

  const isEmail = _isEmail(container.id)

  const objective = objectives.find(o => o.id === container?.objectiveId)

  // NB: We are dispatching `actions.addContentBlock` here for any curious listeners, e.g.,
  // template reducer. We want to add this content block to template.templatePages[?].page.contentBlocks,
  // if we don't it results in a stale editor that requires a refresh to receive updates.
  // See: https://trello.com/c/2pUlXi1V
  const addContentBlock = attrs =>
    onAddContentBlock(container.id, attrs)
      .then(contentBlock => {
        dispatch({type: actions.addContentBlock, payload: contentBlock})
        return contentBlock
      })
      .then(scrollIntoView)

  const scrollIntoView = () => {
    // tests can flap complaining at this line that `document` is null
    // look into ensuring `template-editor.test.js` tests cleanup properly to prevent this
    // https://github.com/digitalonboarding/hendricks/actions/runs/3504740385/jobs/5870613523
    if (!document) return
    const lastContentBlock = document.querySelector(
      `.${container._type}-container > :nth-last-child(1)`
    )

    // If we can't find a block let's not crash ourselves
    if (lastContentBlock) {
      const top = Math.max(
        lastContentBlock.getBoundingClientRect().top - 98, //nav offset + 10 for buffer
        0
      )

      window.scrollTo({top, behavior: "smooth"})
    }
  }

  return (
    <div
      className={cx(classes.root, classes[`root-${container._type}`], {[classes.empty]: isEmpty})}
    >
      <ThemeProvider theme={doTheme}>
        <Menu
          anchorEl={ref.current}
          keepMounted
          open={open}
          anchorOrigin={{
            vertical: "top",
            horizontal: -8,
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          TransitionComponent={SlideAndFade}
          onClose={onTogglePluginMenu}
        >
          {containerPlugins[container._type].map(({Button, type}) => (
            <Button
              classes={{icon: classes.icon}}
              container={container}
              key={type}
              objective={objective}
              onClose={onClosePluginMenu}
              isEmail={isEmail}
              onAddContentBlock={addContentBlock}
            />
          ))}
        </Menu>
      </ThemeProvider>
      <Fab
        className={cx(classes.button, classes[`button-${container._type}`], {
          [classes.buttonActive]: open,
        })}
        color="primary"
        onClick={onTogglePluginMenu}
        ref={ref}
        title="Add a Widget"
      >
        <PlusIcon size={20} />
      </Fab>
    </div>
  )
}

PluginMenu.propTypes = {
  container: containerPropType,
  isEmpty: bool,
}

const useStyles = makeStyles(theme => ({
  button: {
    position: "absolute",
    bottom: 0,
    transition: theme.transitions.create(["transform"], {
      duration: 350,
    }),
  },
  "button-content_container": {
    right: -83,
  },
  "button-page": {
    right: -83,
  },
  "button-template": {
    right: -60,
  },
  "button-message": {
    right: theme.spacing(-4.5),
  },
  buttonActive: {
    transform: "rotate(135deg)",
  },
  icon: {
    minWidth: "33px !important",
  },
  empty: {
    marginTop: theme.spacing(8),
  },
  root: {
    position: "sticky",
    gridColumn: "span 12",
    zIndex: 1000,
  },
  "root-page": {
    bottom: theme.spacing(6),
  },
  "root-template": {
    bottom: theme.spacing(4),
  },
  "root-message": {
    bottom: theme.spacing(3),
  },
}))

export default provideObjectives(PluginMenu)
