import {Button, Grid, Link, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {array, bool, func, object} from "prop-types"
import {PureComponent} from "react"

import {triggerDownload} from "components/csv-download-button/csv-download-button"
import DripPreview from "components/drip-preview/drip-preview"

import {templateContentContext} from "../../contexts/template-content-context"
import Box from "../box/box"
import DocumentTitle from "../document-title/document-title"
import {
  contactFields,
  journeyAccountFields,
} from "../teams/csv-processing-settings/csv-processing-helpers"

export class BatchUploadJourneys extends PureComponent {
  state = {
    fileName: "",
  }

  componentDidMount = () => {
    this.props.fetchTemplateValidity()
    this.props.getObjectives()
  }

  // Build template with certain cols to the left for visibility
  createCsvContent = () => {
    const forVisibility = ["unique_id", "account_number"]
    const objectiveKeys = this.props.objectives.map(o => o.key)

    const fields = [...contactFields, ...journeyAccountFields]
      .reduce((acc, field) => {
        if (!forVisibility.includes(field.value)) {
          return [...acc, field.value]
        }

        return acc
      }, [])
      .concat(objectiveKeys)

    return Promise.resolve([[...forVisibility, ...fields]])
  }

  downloadCsvTemplate = () => {
    triggerDownload(this.createCsvContent, "batch-journey-upload-template.csv")
  }

  onFileUpload = ({target: {files}}) => {
    if (files[0]) {
      this.setState({fileName: files[0]?.name ?? ""})
      this.props.onContinue({
        file: files[0],
        batchType: "journey",
      })
    }
  }

  render() {
    const {
      classes,
      template,
      hasNurturingMessagesAndNoTimelineMarkers,
      timelineMarkers,
      isArchived,
      isNotArchivedOrLive,
    } = this.props
    const {fileName} = this.state

    return (
      <Box>
        <DocumentTitle title={`Campaigns - ${template.name} - Batch Upload`} />
        <Grid container={true} spacing={2} columns={{md: 1, lg: 2}}>
          <Grid item={true} md={1} className={classes.uploadBox}>
            <div>
              {isArchived && (
                <div className={classes.alertDanger}>
                  Warning: You will be unable to batch upload journeys because this campaign is
                  archived.
                </div>
              )}
              {isNotArchivedOrLive && (
                <div className={classes.alertDanger}>
                  Warning: This campaign is not yet in "Live" status, so uploaded contacts will be
                  treated as a test. If this is not a test, please complete the approvals process
                  before uploading.
                </div>
              )}
              <input
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                data-testid="csv-file"
                id="csv-file"
                name="batchUploadCSV"
                onChange={this.onFileUpload}
                style={{display: "none"}}
                type="file"
              />
              <label htmlFor="csv-file">
                <Typography className={classes.helperText}>{fileName ?? "none chosen"}</Typography>
                <Button
                  className={classes.button}
                  color="primary"
                  component="span"
                  variant="contained"
                >
                  Choose A File
                </Button>
              </label>
              <br />
              <br />
              <Link
                href="https://support.digitalonboarding.com/support/solutions/articles/48001186845-csv-file-import-specifications"
                target="_blank"
              >
                Need help creating a file?
              </Link>{" "}
              Or want to <Link onClick={this.downloadCsvTemplate}>download a csv template?</Link>
            </div>
          </Grid>
          <Grid item={true} md={1}>
            <DripPreview timelineMarkers={timelineMarkers} flavor={"batch"} />
            <div className={classes.notifications}>
              {hasNurturingMessagesAndNoTimelineMarkers && (
                <>
                  <Typography className={classes.errorText} gutterBottom={true} variant="caption">
                    There are no message pins in this template's message scheduler; if you continue,
                    only scheduled messages will be sent for this journey.
                  </Typography>
                  <Button
                    color="primary"
                    component={Link}
                    to={`/admin/templates/${template.id}/edit`}
                    variant="contained"
                  >
                    Go to Template Editor
                  </Button>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </Box>
    )
  }
}

BatchUploadJourneys.propTypes = {
  classes: object.isRequired,
  fetchTemplateValidity: func.isRequired,
  getObjectives: func.isRequired,
  hasNurturingMessagesAndNoTimelineMarkers: bool,
  objectives: array.isRequired,
  onContinue: func.isRequired,
  template: object.isRequired,
  timelineMarkers: array.isRequired,
  isArchived: bool,
  isNotArchivedOrLive: bool,
}

const styles = theme => ({
  button: {
    marginBottom: 10,
    marginRight: 10,
  },
  uploadBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > div": {
      textAlign: "center",
    },
  },
  code: {
    backgroundColor: "whitesmoke",
    display: "block",
    overflow: "auto",
  },
  errorBox: {
    width: "100%",
    maxHeight: "300px",
    overflow: "auto",
  },
  errorOne: {
    fontWeight: "bold",
    color: "indianred",
  },
  errorTwo: {
    fontWeight: "bold",
    color: "red",
  },
  alertDanger: {
    color: "indianred",
    paddingBottom: "1em",
  },
  field: {
    marginBottom: 10,
  },
  helperText: {
    display: "block",
    marginBottom: 5,
  },
  link: {
    textDecoration: "none",
  },
  progress: {
    margin: "15px 0",
  },
  errorText: {
    marginTop: 10,
    color: theme.palette.error.main,
  },
  notifications: {
    textAlign: "center",
  },
})

export default withStyles(styles)(templateContentContext(BatchUploadJourneys))
