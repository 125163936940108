import Box from "@mui/material/Box"

import {ExportButton} from "components/export-button/export-button"

import {fetchContactUnsubscribes} from "lib/api"

const ButtonBar = () => (
  <Box
    sx={{
      marginBottom: 1.25,
      "& button": {
        marginRight: 1.25,
      },
    }}
  >
    <ExportButton
      fetchRecords={fetchContactUnsubscribes}
      filename="contacts_unsubscribes"
      title="Download Unsubscribes"
    />
  </Box>
)

export default ButtonBar
