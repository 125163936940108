import {Link, Tooltip, Typography} from "@mui/material"
import {TableCell, TableRow} from "@mui/material"
import {lighten} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {array, object, string} from "prop-types"
import {AiOutlineTrophy as RewardsIcon} from "react-icons/ai"
import {BiNetworkChart as CCEIcon} from "react-icons/bi"
import {
  FaCheckCircle as ApprovedIcon,
  FaArchive as ArchivedIcon,
  FaTools as DraftIcon,
  FaLeaf as LiveIcon,
  FaVolumeMute as MuteIcon,
  FaExclamationTriangle as NotApprovedIcon,
  FaStreetView as TargetingIcon,
  FaStar as TargetingPriorityIcon,
} from "react-icons/fa"
import {FiEdit as EditIcon} from "react-icons/fi"
import {
  MdLightbulbOutline as InsightsIcon,
  MdOutlinePendingActions as PendingIcon,
  MdOutlineRotateLeft as ReEnrollIcon,
  MdSend as SendIcon,
} from "react-icons/md"
import {Link as RouterLink} from "react-router-dom"

import {distanceDateTime} from "lib/date-time-formatters"
import useFeatures from "lib/hooks/use-features"
import humanize from "lib/string/humanize"
import pluralize from "lib/string/pluralize"

const StatusIcon = ({status}) => {
  switch (status) {
    case "live":
      return <LiveIcon />
    case "draft":
      return <DraftIcon />
    case "approved":
      return <ApprovedIcon />
    case "not-approved":
      return <NotApprovedIcon />
    case "archived":
      return <ArchivedIcon />
    case "pending-approval":
      return <PendingIcon />
    default:
      return null
  }
}

const statusLabel = status => {
  switch (status) {
    case "not-approved":
      return "Rejected"
    case "pending-approval":
      return "Pending"
    default:
      return humanize(status)
  }
}

StatusIcon.propTypes = {
  status: string.isRequired,
}

const useStyles = makeStyles(theme => ({
  campaignTitleRow: {
    marginBottom: theme.spacing(4),
  },
  statusIconWrapper: {
    width: 120,
    marginRight: theme.spacing(1),
    display: "inline-block",
  },
  mainLink: {
    marginBottom: theme.spacing(1),
    textDecoration: "none",
    color: theme.palette.text.primary,
    fontSize: 16,
  },
  campaignStatusBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
  },
  campaignStatusBarItem: {
    display: "inline-flex",
    alignItems: "center",
    color: lighten(theme.palette.brand.darkBlue, 0.9),
    "& svg": {
      marginRight: theme.spacing(1),
    },
  },
  campaignStatusBarItemEnabled: {
    color: theme.palette.brand.darkBlue,
  },
  isMuted: {
    backgroundColor: theme.palette.error.main,
    color: "#ffffff",
    borderRadius: theme.spacing(0.5),
    padding: `0 ${theme.spacing(0.5)}`,
    "& svg": {
      marginRight: 0,
    },
    "& span": {
      marginLeft: theme.spacing(1),
    },
  },
  statusIcon: {
    height: 30,
    borderRadius: 15,
    fontWeight: "bold",
    fontSize: 12,
    textTransform: "uppercase",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(1),
    "& svg": {
      marginRight: theme.spacing(1),
    },
    "&.live": {
      backgroundColor: lighten(theme.palette.brand.lightBlue, 0.9),
      color: theme.palette.brand.lightBlue,
    },
    "&.draft": {
      backgroundColor: lighten(theme.palette.brand.darkBlue, 0.9),
      color: theme.palette.brand.darkBlue,
    },
    "&.approved": {
      backgroundColor: lighten(theme.palette.brand.lightGreen, 0.9),
      color: theme.palette.brand.lightGreen,
    },
    "&.not-approved": {
      backgroundColor: lighten(theme.palette.brand.lightCoral, 0.9),
      color: theme.palette.brand.lightCoral,
    },
    "&.archived": {
      backgroundColor: lighten(theme.palette.brand.darkestGray, 0.9),
      color: theme.palette.brand.darkestGray,
    },
    "&.pending-approval": {
      backgroundColor: lighten(theme.palette.brand.mediumGray, 0.9),
      color: theme.palette.brand.mediumGray,
    },
  },
  actions: {
    "& a": {
      display: "inline-flex",
      fontSize: 18,
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      aspectRatio: "1",
      width: "100%",
      maxWidth: 32,
      maxHeight: 32,
      marginRight: theme.spacing(1),
      color: theme.palette.text.primary,
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
      "&.primary": {
        color: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: lighten(theme.palette.primary.light, 0.9),
        },
      },
    },
  },
  targetingPriorityIcon: {
    color: theme.palette.warning.main,
  },
}))

const hasColumn = (columns, column) => columns.some(({field}) => field === column)

const CampaignListRow = ({columns, row}) => {
  const classes = useStyles()
  const {hasFeature} = useFeatures()
  const {
    facts,
    id,
    insertedAt,
    isCceEnabled,
    isMuted,
    isRewardEnabled,
    isTargetingPriority,
    launchedAt,
    name,
    status,
    targetingOrder,
    type,
  } = row
  const showStatusLabels = columns.length < 6

  return (
    <TableRow key={id}>
      <TableCell>
        <div className={classes.campaignTitleRow}>
          {hasFeature("campaign-approval") && (
            <div className={classes.statusIconWrapper}>
              <div className={cx(classes.statusIcon, status)}>
                <StatusIcon status={status} /> {statusLabel(status)}
              </div>
            </div>
          )}
          <Typography
            variant="subtitle1"
            className={classes.mainLink}
            component={RouterLink}
            to={`/admin/templates/${id}/journeys`}
          >
            {name}
          </Typography>
        </div>
        <div className={classes.campaignStatusBar}>
          {!hasFeature("campaign-approval") && (
            <Tooltip
              title={`This campaign is ${status === "archived" ? "" : "not"} archived`}
              placement="bottom"
            >
              <div
                className={cx(classes.campaignStatusBarItem, {
                  [classes.campaignStatusBarItemEnabled]: status === "archived",
                })}
              >
                <ArchivedIcon size={18} title="Archived" /> {showStatusLabels && "Archived"}
              </div>
            </Tooltip>
          )}
          <Tooltip
            title={`This campaign 
            ${isTargetingPriority ? "has priority" : ""} 
            ${!isTargetingPriority && targetingOrder !== null ? "has" : ""} 
            ${!isTargetingPriority && targetingOrder === null ? "does not have" : ""} 
          targeting conditions`}
            placement="bottom"
          >
            <Link
              to={`/admin/templates/${id}/targeting`}
              component={RouterLink}
              color="brand.darkBlue"
              sx={{position: "relative"}}
              className={cx(classes.campaignStatusBarItem, {
                [classes.campaignStatusBarItemEnabled]:
                  targetingOrder !== null || isTargetingPriority,
              })}
            >
              <TargetingIcon size={18} className={classes.targetingIcon} />
              {isTargetingPriority && (
                <div style={{position: "absolute", top: -10, left: 8}}>
                  <TargetingPriorityIcon size={12} className={classes.targetingPriorityIcon} />
                </div>
              )}
              {showStatusLabels && "Targeting"}
            </Link>
          </Tooltip>
          <Tooltip
            title={`This campaign ${
              type === "tactical" ? "has" : "does not have"
            } re-enrollment enabled`}
            placement="bottom"
          >
            <div
              className={cx(classes.campaignStatusBarItem, {
                [classes.campaignStatusBarItemEnabled]: type === "tactical",
              })}
            >
              <ReEnrollIcon size={22} /> {showStatusLabels && "Re-Enrollment"}
            </div>
          </Tooltip>
          <Tooltip
            title={`This campaign ${
              isCceEnabled ? "has" : "does not have"
            } cross channel engagement content enabled`}
            placement="bottom"
          >
            <div
              className={cx(classes.campaignStatusBarItem, {
                [classes.campaignStatusBarItemEnabled]: isCceEnabled,
              })}
            >
              <CCEIcon size={18} /> {showStatusLabels && "Cross Channel"}
            </div>
          </Tooltip>
          <Tooltip
            title={`This campaign ${isRewardEnabled ? "has" : "does not have"} rewards enabled`}
            placement="bottom"
          >
            <div
              className={cx(classes.campaignStatusBarItem, {
                [classes.campaignStatusBarItemEnabled]: isRewardEnabled,
              })}
            >
              <RewardsIcon size={18} title="Reward" /> {showStatusLabels && "Reward"}
            </div>
          </Tooltip>
          <Tooltip
            title={
              isMuted
                ? "This campaign is muted and can't send messages"
                : "This campaign is not muted"
            }
            placement="bottom"
          >
            <div className={cx(classes.campaignStatusBarItem, {[classes.isMuted]: isMuted})}>
              <MuteIcon size={18} /> {showStatusLabels && <span>Muted</span>}
            </div>
          </Tooltip>
        </div>
      </TableCell>
      {hasColumn(columns, "inserted_at") && (
        <TableCell style={{verticalAlign: "top"}}>{distanceDateTime(insertedAt)}</TableCell>
      )}
      {hasColumn(columns, "launched_at") && (
        <TableCell style={{verticalAlign: "top"}}>
          {launchedAt && distanceDateTime(launchedAt)}
        </TableCell>
      )}
      {hasColumn(columns, "min_contact_added_at") && (
        <TableCell style={{verticalAlign: "top"}}>
          {facts?.minContactAddedAt && distanceDateTime(facts?.minContactAddedAt)}
        </TableCell>
      )}
      {hasColumn(columns, "max_contact_added_at") && (
        <TableCell style={{verticalAlign: "top"}}>
          {facts?.maxContactAddedAt && distanceDateTime(facts?.maxContactAddedAt)}
        </TableCell>
      )}
      {hasColumn(columns, "journey_count") && (
        <TableCell style={{textAlign: "right", verticalAlign: "top"}}>
          <div>
            <Typography variant="subtitle1">
              {(facts?.journeyCount || 0).toLocaleString()}
            </Typography>
            <Typography variant="caption">{pluralize("journey", facts?.journeyCount)}</Typography>
          </div>
        </TableCell>
      )}
      <TableCell style={{width: 160, verticalAlign: "top"}}>
        <div className={classes.actions}>
          <RouterLink
            className={classes.actionButton}
            to={`/admin/templates/${id}/insights`}
            title="Insights"
          >
            <InsightsIcon />
          </RouterLink>
          <RouterLink
            className={classes.actionButton}
            to={`/admin/templates/${id}/edit`}
            title="Edit"
          >
            <EditIcon />
          </RouterLink>
          <RouterLink
            className={cx(classes.actionButton, "primary")}
            disabled={status === "archived"}
            to={`/admin/templates/${id}/journeys/new`}
            title="Send a journey"
          >
            <SendIcon />
          </RouterLink>
        </div>
      </TableCell>
    </TableRow>
  )
}

CampaignListRow.propTypes = {
  columns: array.isRequired,
  row: object.isRequired,
}

export default CampaignListRow
