import {Button, FormControl, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func, object, string} from "prop-types"
import {useState} from "react"
import {MdDelete as TrashIcon} from "react-icons/md"

import FileBrowser from "../../../file-browser/file-browser"
import UploadedImage from "../../../uploaded-image/uploaded-image"

const useStyles = makeStyles(theme => ({
  iconSrcWrapper: {
    margin: "15px 0",
  },
  fakeFormLabel: {
    color: theme.palette.text.secondary,
  },
  iconWrapper: {
    marginBottom: 10,
    "& > *": {
      verticalAlign: "middle",
    },
  },
  iconBackground: {
    backgroundColor: props => props.backgroundColor,
    padding: "8px 24px", // believe it or not this is a hardcoded value for large buttons
    display: "inline-block",
    marginRight: 15,
    "& img": {
      display: "block",
    },
    borderRadius: 4,
  },
  iconContainer: {
    height: 24,
  },
}))

const PluginIconInput = ({iconSrcInitial, onChange, backgroundColor, theme}) => {
  const classes = useStyles({
    backgroundColor:
      backgroundColor ||
      theme?.overrides?.MuiButton?.containedPrimary?.backgroundColor ||
      theme?.palette?.primary?.main,
  })
  const [fileBrowserOpen, setFileBrowserOpen] = useState(false)
  const [iconSrc, setIconSrc] = useState(iconSrcInitial)
  const iconSrcUrl = iconSrc && new URL(iconSrc)
  const baseIconSrc = iconSrc && iconSrc.replace(iconSrcUrl.search, "")

  const onSelectImage = file => {
    const iconSrc = `${process.env.REACT_APP_API_URL}/files/${file.id}?variant=icon`

    setFileBrowserOpen(false)
    setIconSrc(iconSrc)
    onChange(iconSrc)
  }

  const onRemoveIconSrc = () => {
    setIconSrc("")
    onChange("")
  }

  return (
    <FormControl margin="normal">
      <Typography className={classes.fakeFormLabel} variant="caption">
        Custom Button Icon
      </Typography>
      <div className={classes.iconSrcWrapper}>
        {iconSrc && (
          <div className={classes.iconWrapper}>
            <div className={classes.iconBackground}>
              <div className={classes.iconContainer}>
                <UploadedImage src={baseIconSrc} variant="icon" />
              </div>
            </div>
            <Button onClick={onRemoveIconSrc}>
              <TrashIcon /> Remove Icon
            </Button>
          </div>
        )}
        <Button
          onClick={() => setFileBrowserOpen(true)}
          variant="outlined"
          size="small"
          color="grey"
        >
          Upload {iconSrc && "New"}
        </Button>
      </div>
      <Typography className={classes.fakeFormLabel} variant="caption">
        Maximum dimensions of 24px high by 100px wide
      </Typography>

      {fileBrowserOpen && (
        <FileBrowser
          flavor={{
            code: "button_icon",
            pluralLabel: "Button Icons",
            variant: "icon",
          }}
          isOpen={fileBrowserOpen}
          onClose={() => setFileBrowserOpen(false)}
          onSelect={onSelectImage}
        />
      )}
    </FormControl>
  )
}
PluginIconInput.propTypes = {
  backgroundColor: string,
  onChange: func.isRequired,
  iconSrcInitial: string,
  theme: object,
}
export default PluginIconInput
