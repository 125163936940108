import {Box, IconButton, Tooltip, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {arrayOf, func, object, shape, string} from "prop-types"
import {useState} from "react"
import {FaRegTimesCircle as RemoveIcon} from "react-icons/fa"

import {LibraryItem} from "../content-library/content-library"
import ConfirmDialog from "../dialogs/confirm-dialog"
import {updateObjectiveRow} from "./campaign-builder-context"

const useRemoveButtonStyles = makeStyles(theme => ({
  removeButton: {
    position: "absolute",
    right: -24,
    top: -24,
    "& svg": {color: "red"},
  },
  popper: {margin: "-0.5em 0 0 -1.75em"},
  tooltip: {
    backgroundColor: theme.palette.grey["800"],
    color: theme.palette.grey["50"],
    fontWeight: "bold",
  },
}))

const RemoveButton = ({contentItem, onRemove}) => {
  const classes = useRemoveButtonStyles()

  return (
    <Tooltip
      classes={{popper: classes.popper, tooltip: classes.tooltip}}
      placement="right-start"
      title="Remove content from campaign"
    >
      <IconButton
        aria-label="remove"
        className={classes.removeButton}
        onClick={() => onRemove(contentItem)}
        size="medium"
      >
        <RemoveIcon />
      </IconButton>
    </Tooltip>
  )
}

RemoveButton.propTypes = {
  contentItem: object.isRequired,
  onRemove: func,
}

const useReviewStepStyles = makeStyles(theme => ({
  title: {
    fontStyle: "italic",
    marginBottom: theme.spacing(2),
  },
  objectiveRow: {
    marginBottom: theme.spacing(2),
  },
  objectiveName: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  contentItemsWrapper: {
    display: "grid",
    gridColumnGap: theme.spacing(6),
    gridRowGap: theme.spacing(2),
    msGridRowGap: theme.spacing(1),
    fallbacks: [{display: "-ms-grid"}],
    gridTemplateColumns: "repeat(auto-fill, 150px)",
    msGridTemplateColumns: "repeat(auto-fill, 150px)",
  },
  contentItem: {
    position: "relative",
  },
}))

const ReviewStep = ({dispatch, wizardState: {campaignType, objectiveRows}}) => {
  const classes = useReviewStepStyles()
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const [itemToRemove, setItemToRemove] = useState(null)

  const onConfirmRemove = (objectiveRowIndex, contentType, contentId) => {
    const updated = removeContentItem(objectiveRows[objectiveRowIndex], contentType, contentId)

    dispatch(updateObjectiveRow({objectiveRow: updated, objectiveRowIndex}))
  }

  const massMessaging = campaignType === "mass-messaging"

  const onDuplicateToggle = ({id, objectiveId, _type}, objectiveRowIndex) => {
    const toggleDuplicate = (objectiveRow, contentType, contentId) => {
      switch (contentType) {
        case "message":
          return {
            ...objectiveRow,
            messages: objectiveRow.messages.map(message => {
              if (message.id !== contentId) {
                return message
              }

              return {...message, shouldDuplicate: !message.shouldDuplicate}
            }),
          }
        case "page":
          return {
            ...objectiveRow,
            page: {...objectiveRow.page, shouldDuplicate: !objectiveRow.page.shouldDuplicate},
          }
        default:
          return objectiveRow
      }
    }
    const updated = toggleDuplicate(objectiveRows[objectiveRowIndex], _type, id)

    dispatch(updateObjectiveRow({objectiveRow: updated, objectiveRowIndex}))
  }

  const removeContentItem = (objectiveRow, contentType, contentId) => {
    switch (contentType) {
      case "message":
      case "email":
      case "sms":
        return {
          ...objectiveRow,
          messages: objectiveRow.messages.filter(message => message.id !== contentId),
        }
      case "page":
        return {
          ...objectiveRow,
          page: objectiveRow.page?.id === contentId ? null : objectiveRow.page,
        }
      default:
        return objectiveRow
    }
  }

  const confirmRemoveFor = ({contentName, id, objectiveId, type, _type}, i) => {
    // FIXME: Inconsistently engineered new content items should be normalized
    //   so this kind of tricky inspection is not required to figure out the type
    const contentType = contentName === "New Page" ? "page" : _type || type
    setItemToRemove({contentId: id, contentType, objectiveRowIndex: i, objectiveId})
    setIsConfirmDialogOpen(true)
  }

  const resetRemove = () => {
    setItemToRemove(null)
    setIsConfirmDialogOpen(false)
  }

  const doRemove = () => {
    const {contentId, contentType, objectiveRowIndex} = itemToRemove
    onConfirmRemove(objectiveRowIndex, contentType, contentId)
    resetRemove()
  }

  const contentItemsLength = (() => {
    if (objectiveRows) {
      const contentItems = objectiveRows.reduce((acc, objectiveRow) => {
        const pageCount = objectiveRow.page?.id ? 1 : 0

        return acc + pageCount + objectiveRow.messages.length
      }, 0)

      return contentItems > 1
    }

    return false
  })()

  return (
    <>
      <Box my={3}>
        <Typography className={classes.title}>
          This is what your campaign looks like so far...
        </Typography>
        {objectiveRows.map((objectiveRow, i) => (
          <div className={classes.objectiveRow} key={objectiveRow.page?.id || i}>
            {massMessaging ? (
              <Typography className={classes.objectiveName}>Mass Messaging Campaign</Typography>
            ) : (
              <Typography className={classes.objectiveName}>
                Objective: {objectiveRow.objective?.name || "None"}
              </Typography>
            )}
            <div className={classes.contentItemsWrapper}>
              {objectiveRow.page && (
                <div className={classes.contentItem}>
                  {contentItemsLength && (
                    <RemoveButton
                      contentItem={objectiveRow.page}
                      onRemove={contentItem => confirmRemoveFor(contentItem, i)}
                    />
                  )}
                  <LibraryItem
                    {...objectiveRow.page}
                    type="page"
                    onToggleDuplicate={
                      objectiveRow.page.id === "new"
                        ? null
                        : () => onDuplicateToggle(objectiveRow.page, i)
                    }
                  />
                </div>
              )}
              {objectiveRow.messages.map(message => (
                <div className={classes.contentItem} key={message.id}>
                  {contentItemsLength && (
                    <RemoveButton
                      contentItem={message}
                      onRemove={contentItem => confirmRemoveFor(contentItem, i)}
                    />
                  )}
                  <LibraryItem
                    {...message}
                    onToggleDuplicate={
                      ["new-email", "new-sms"].includes(message.id)
                        ? null
                        : () => onDuplicateToggle(message, i)
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
        {isConfirmDialogOpen && (
          <ConfirmDialog
            cancelText="Cancel"
            continueText="Remove"
            content="Removing content from the campaign cannot be undone."
            key="isConfirmDialogOpen"
            onClose={resetRemove}
            onConfirm={doRemove}
            open={true}
            title="Remove Content?"
          />
        )}
      </Box>
    </>
  )
}

ReviewStep.propTypes = {
  dispatch: func,
  wizardState: shape({
    objectiveRows: arrayOf(
      shape({
        objective: shape({name: string}),
      })
    ),
    campaignType: string,
  }),
}

export default ReviewStep
