import {FaRegFileAlt as PageIcon} from "react-icons/fa"
import {MdEmail as EmailIcon, MdTextsms as SMSIcon} from "react-icons/md"
import shortid from "shortid"

import {createEmptyDraftContent} from "lib/draft-js/editor-helpers"
import score from "lib/string/score"

export const availableContentTypes = [
  {label: "All", "data-name": "all"},
  {label: "Pages", "data-name": "page"},
  {label: "Emails", "data-name": "email"},
  {label: "SMS", "data-name": "sms"},
]

export const contentTypesForSelectedContentType = selection => {
  if (selection === "all") return ["page", "email", "sms"]
  return [selection]
}

export const groupByObjective = (items, objective) =>
  items.filter(item => (!!objective ? item.objectiveId === objective.id : !item.objectiveId))

export const filterItems = (items, filter) =>
  items
    .map(item => ({item, similarity: filter ? score(item.contentName.toLowerCase(), filter) : 1}))
    .filter(
      ({similarity, item}) =>
        !filter ||
        similarity >= 0.85 ||
        item.contentName.toLowerCase().includes(filter) ||
        item.id === "new"
    )
    .sort((lhs, rhs) =>
      !filter ? lhs.item.contentName - rhs.item.contentName : lhs.similarity - rhs.similarity
    )
    .map(({item}) => item)

export const libraryItemsToLibrary = (items, objectives) => ({
  ...objectives.reduce(
    (library, currObjective) => ({
      ...library,
      [currObjective.name]: groupByObjective(items, currObjective),
    }),
    {}
  ),
  "No objective": groupByObjective(items, null),
})

export const libraryIcon = type => {
  switch (type) {
    case "page":
      return PageIcon
    case "sms":
      return SMSIcon
    case "email":
      return EmailIcon
    default:
      throw new Error("unknown icon type supplied")
  }
}

export const humanizeType = type => {
  switch (type) {
    case "page":
      return "Page"
    case "sms":
      return "SMS"
    case "email":
      return "Email"
    case "emailWithPersonalization":
      return "Email containing personalization"
    default:
      throw new Error("unknown type supplied")
  }
}

export const createEmptyPayload = type =>
  type === "sms"
    ? {
        body: createEmptyDraftContent(),
        linksTo: "journey-page",
        slug: shortid.generate(),
      }
    : {
        subject: createEmptyDraftContent(),
      }

export const getNewContentName = (type, objective, order) =>
  ["New", objective?.name, humanizeType(type), order].filter(part => Boolean(part)).join(" ")

export const buildNewContentItem = (type, objective, order) => {
  const base = {id: `new-${type}`, type, objectiveId: objective?.id}

  if (type === "page") return base

  return {
    ...base,
    bodyPreview: type === "sms" ? "New SMS" : undefined,
    contentName: getNewContentName(type, objective, order),
    isTransactional: false,
    payload: createEmptyPayload(type),
  }
}
