import startCase from "lodash/startCase"
import shortid from "shortid"

import {
  CHECKBOXES,
  DATE,
  EMAIL,
  MULTIPLE_CHOICE,
  NUMBER,
  PARAGRAPH,
  SHORT_ANSWER,
} from "components/content-block-editor/plugins/survey/survey-question-types"

import {
  accountFields,
  commonFields,
  contactFields,
} from "../teams/csv-processing-settings/csv-processing-helpers.jsx"

export const actions = {
  ADD_TARGETING_CONDITION: "ADD_TARGETING_CONDITION",
  ADD_TARGETING_GROUP: "ADD_TARGETING_GROUP",
  REMOVE_TARGETING_CONDITION: "REMOVE_TARGETING_CONDITION",
  REMOVE_TARGETING_GROUP: "REMOVE_TARGETING_GROUP",
  SET_FORM_STATE: "SET_FORM_STATE",
  SET_IS_LOADING: "SET_IS_LOADING",
  SET_IS_TARGETING_PRIORITY: "SET_IS_TARGETING_PRIORITY",
  SET_JOURNEY_CREATION_STRATEGY: "SET_JOURNEY_CREATION_STRATEGY",
  SET_TARGETING_IMPACT: "SET_TARGETING_IMPACT",
  SET_TARGETING_GROUP_LOGICAL_OPERATOR: "SET_TARGETING_GROUP_LOGICAL_OPERATOR",
  SET_TARGETING_GROUPS: "SET_TARGETING_GROUPS",
  UPDATE_TARGETING_CONDITION: "UPDATE_TARGETING_CONDITION",
  UPDATE_TARGETING_GROUP: "UPDATE_TARGETING_GROUP",
}

export const FORM_STATES = {
  CHECKING_IMPACT: "checkingImpact",
  CLEAN: "clean",
  COMPLETE: "complete",
  DIRTY: "dirty",
  ERROR: "error",
  PRIOR_IMPACT: "priorImpact",
  PRISTINE: "pristine",
  READY_TO_SUBMIT: "readyToSubmit",
  REMOVE: "remove",
  SUBMITTING: "submitting",
}

export const TARGETING_IMPACT = {
  AVAILABLE: "available",
  CANCELLED: "cancelled",
  COMPLETED: "completed",
  EXECUTING: "executing",
  FAILED: "failed",
  NONE: "none",
}

export const initialState = {
  formState: FORM_STATES.CLEAN,
  isLoading: false,
  isTargetingPriority: false,
  targetingImpact: null,
  targetingAccountPriority: "OPENED_DATE_DESC",
  targetingGroupLogicalOperator: "AND",
  targetingGroups: [],
}

const getNewTargetingConditionAttrs = targetingGroupId => ({
  field: "address",
  id: shortid.generate(),
  isNew: true,
  metaSubKey: "",
  meta: {},
  model: "contact",
  operator: "contains",
  targetingGroupId,
  value: "",
})

const getNewTargetingGroupAttrs = () => {
  const id = shortid.generate()

  return {
    id,
    isNew: true,
    logicalOperator: "AND",
    targetingConditions: [getNewTargetingConditionAttrs(id)],
  }
}

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.ADD_TARGETING_CONDITION:
      return {
        ...state,
        formState: FORM_STATES.DIRTY,
        targetingGroups: state.targetingGroups.map(targetingGroup =>
          targetingGroup.id === action.targetingGroupId
            ? {
                ...targetingGroup,
                targetingConditions: targetingGroup.targetingConditions.concat(
                  getNewTargetingConditionAttrs(targetingGroup.id)
                ),
              }
            : targetingGroup
        ),
      }
    case actions.ADD_TARGETING_GROUP:
      return {
        ...state,
        formState: FORM_STATES.DIRTY,
        targetingGroups: [...state.targetingGroups, getNewTargetingGroupAttrs()],
      }
    case actions.UPDATE_TARGETING_GROUP:
      return {
        ...state,
        formState: FORM_STATES.DIRTY,
        targetingGroups: state.targetingGroups.map(targetingGroup =>
          targetingGroup.id === action.targetingGroup.id
            ? {...targetingGroup, ...action.targetingGroup}
            : targetingGroup
        ),
      }
    case actions.UPDATE_TARGETING_CONDITION:
      return {
        ...state,
        formState: FORM_STATES.DIRTY,
        targetingGroups: state.targetingGroups.map(targetingGroup => {
          switch (targetingGroup.id) {
            case action.targetingCondition.targetingGroupId:
              return {
                ...targetingGroup,
                targetingConditions: targetingGroup.targetingConditions.map(targetingCondition =>
                  targetingCondition.id === action.targetingCondition.id
                    ? {...targetingCondition, ...action.targetingCondition}
                    : targetingCondition
                ),
              }
            default:
              return targetingGroup
          }
        }),
      }
    case actions.REMOVE_TARGETING_GROUP: {
      const targetingGroups = state.targetingGroups.filter(
        targetingGroup => targetingGroup.id !== action.targetingGroupId
      )

      return {
        ...state,
        formState: targetingGroups.length === 0 ? FORM_STATES.REMOVE : FORM_STATES.DIRTY,
        isTargetingPriority: targetingGroups.length === 0 ? false : state.isTargetingPriority,
        targetingGroups,
      }
    }
    case actions.REMOVE_TARGETING_CONDITION: {
      return {
        ...state,
        formState: FORM_STATES.DIRTY,
        targetingGroups: state.targetingGroups.map(targetingGroup => {
          switch (targetingGroup.id) {
            case action.targetingCondition.targetingGroupId:
              return {
                ...targetingGroup,
                targetingConditions: targetingGroup.targetingConditions.filter(
                  targetingCondition => targetingCondition.id !== action.targetingCondition.id
                ),
              }
            default:
              return targetingGroup
          }
        }),
      }
    }
    case actions.SET_JOURNEY_CREATION_STRATEGY: {
      return {
        ...state,
        formState: FORM_STATES.DIRTY,
        journeyCreationStrategy: action.journeyCreationStrategy,
      }
    }
    default:
      const {type, ...restAction} = action
      if (actions[type]) return {...state, formState: FORM_STATES.DIRTY, ...restAction}
      else throw new Error(`unknown action type (${type}) specified`)
  }
}

const booleanFields = [
  "email_authorized",
  "email_opted_in",
  "email_verified",
  "sms_authorized",
  "sms_opted_in",
  "sms_verified",
]

export const availableAccountFields = [...accountFields, ...commonFields]
  .map(field => ({...field, value: `${field.value}`}))
  .sort(({name: lhsName}, {name: rhsName}) => {
    if (lhsName < rhsName) return -1
    if (lhsName > rhsName) return 1
    return 0
  })

export const availableContactFields = [
  ...contactFields,
  ...commonFields,
  {name: "Household Size", value: "household_size"},
]
  .filter(
    // in addition to being a potential minefield about targeting unsubscribed users
    // targeting boolean fields has some ramifications for how we cast data
    // as such, for now, we just don't allow people to target based on these fields.
    ({value}) => !booleanFields.includes(value)
  )
  .sort(({name: lhsName}, {name: rhsName}) => {
    if (lhsName < rhsName) return -1
    if (lhsName > rhsName) return 1
    return 0
  })

const retargetingFields = [
  "card_on_file",
  "direct_deposit",
  "enrollment",
  "journey_status",
  "mobile_app_download",
  "opt_in",
  "survey",
]

export const isSurveySingleAnswerAvailable = (field, meta) =>
  field === "survey" &&
  meta.questionTitle !== "entire survey" &&
  [SHORT_ANSWER, PARAGRAPH, NUMBER, DATE, EMAIL].includes(meta.type)

export const isSurveyMultiAnswerAvailable = (field, meta) =>
  field === "survey" &&
  meta.questionTitle !== "entire survey" &&
  [CHECKBOXES, MULTIPLE_CHOICE].includes(meta.type)

export const isSurveyEntireAvailable = (field, meta) =>
  field === "survey" && meta.questionTitle === "entire survey"

export const availableOperators = [
  {
    name: "contains",
    value: "contains",
    isAvailable: (field, meta) =>
      !["birthdate", "household_size", ...retargetingFields].includes(field) ||
      isSurveySingleAnswerAvailable(field, meta),
    availableTypes: "string",
    helpText:
      "The value you set here can be any string, and will be compared in the database as a string.",
  },
  {
    name: "does not contain",
    value: "not_contains",
    isAvailable: (field, meta) =>
      !["birthdate", "household_size", ...retargetingFields].includes(field) ||
      isSurveySingleAnswerAvailable(field, meta),
    availableTypes: "string",
    helpText:
      "The value you set here can be any string, and will be compared in the database as a string.",
  },
  {
    name: "starts with",
    value: "starts_with",
    isAvailable: (field, meta) =>
      !["birthdate", "household_size", ...retargetingFields].includes(field) ||
      isSurveySingleAnswerAvailable(field, meta),
    availableTypes: "string",
    helpText:
      "The value you set here can be any string, and will be compared in the database as a string.",
  },
  {
    name: "is",
    value: "is",
    isAvailable: (field, meta) =>
      !["birthdate", ...retargetingFields].includes(field) ||
      isSurveySingleAnswerAvailable(field, meta),
    availableTypes: "number, date, or string",
    helpText:
      "The value you set here can be a number, date, or plain text. Our database will attempt to use the most appropriate type (a number, a date, or text - in that order) on what you enter here. If the value in the contact isn't the same type as what you enter here, the contact's value will be considered to be empty.",
  },
  {
    name: "is not",
    value: "is_not",
    isAvailable: (field, meta) =>
      !["birthdate", ...retargetingFields].includes(field) ||
      isSurveySingleAnswerAvailable(field, meta),
    availableTypes: "number, date, or string",
    helpText:
      "The value you set here can be a number, date, or plain text. Our database will attempt to use the most appropriate type (a number, a date, or text - in that order) on what you enter here. If the value in the contact isn't the same type as what you enter here, the contact's value will be considered to be empty.",
  },
  {
    name: "is not on any of the contact's accounts",
    value: "does_not_match_any",
    isAvailable: field => ["product.code", "product.services", "product.type"].includes(field),
    availableTypes: "string",
  },
  {
    name: "greater than",
    value: "greater_than",
    isAvailable: (field, meta) =>
      !["birthdate", ...retargetingFields].includes(field) ||
      isSurveySingleAnswerAvailable(field, meta),
    availableTypes: "number",
    helpText: "The value you set here must be a number.",
  },
  {
    name: "less than",
    value: "less_than",
    isAvailable: (field, meta) =>
      !["birthdate", ...retargetingFields].includes(field) ||
      isSurveySingleAnswerAvailable(field, meta),
    availableTypes: "number",
    helpText: "The value you set here must be a number.",
  },
  {
    name: "within last x days, includes today’s date",
    value: "within_last",
    isAvailable: (field, meta) =>
      !["birthdate", "household_size", ...retargetingFields].includes(field) ||
      isSurveySingleAnswerAvailable(field, meta),
    availableTypes: "non-negative whole number",
    helpText:
      "The value you set here can be a number. Our database will attempt to convert the values into a number. If the value in the contact isn't a number, the contact's value will be considered to be empty.",
  },
  {
    name: "within the next x days, includes today’s date",
    value: "within_next_x_days",
    isAvailable: (field, meta) =>
      !["birthdate", "household_size", ...retargetingFields].includes(field) ||
      isSurveySingleAnswerAvailable(field, meta),
    availableTypes: "non-negative whole number",
    helpText:
      "The value you set here can be a number. Our database will attempt to convert the values into a number. If the value in the contact isn't a number, the contact's value will be considered to be empty.",
  },
  {
    name: "more than x days ago",
    value: "within_at_least",
    isAvailable: (field, meta) =>
      !["birthdate", "household_size", ...retargetingFields].includes(field) ||
      isSurveySingleAnswerAvailable(field, meta),
    availableTypes: "non-negative whole number",
    helpText:
      "The value you set here can be a number. Our database will attempt to convert the values into a number. If the value in the contact isn't a number, the contact's value will be considered to be empty.",
  },
  {
    name: "more than x days from now",
    value: "more_than_x_days_from_now",
    isAvailable: (field, meta) =>
      !["birthdate", "household_size", ...retargetingFields].includes(field) ||
      isSurveySingleAnswerAvailable(field, meta),
    availableTypes: "non-negative whole number",
    helpText:
      "The value you set here can be a number. Our database will attempt to convert the values into a number. If the value in the contact isn't a number, the contact's value will be considered to be empty.",
  },
  {
    name: "is today's date",
    value: "is_today",
    isAvailable: (field, meta) =>
      !["household_size", ...retargetingFields].includes(field) ||
      isSurveySingleAnswerAvailable(field, meta),
    hideValueInput: true,
  },
  {
    name: "is tomorrow's date",
    value: "is_tomorrow",
    isAvailable: (field, meta) =>
      !["household_size", ...retargetingFields].includes(field) ||
      isSurveySingleAnswerAvailable(field, meta),
    hideValueInput: true,
  },
  {
    name: "on or before",
    value: "before_inclusive",
    isAvailable: (field, meta) =>
      !["household_size", ...retargetingFields].includes(field) ||
      isSurveySingleAnswerAvailable(field, meta),
    availableTypes: "date (YYYY-MM-DD)",
    helpText:
      "The value you set here must be a date (YYYY-MM-DD). If the selected field is the same date or earlier, the record will match.",
  },
  {
    name: "on or after",
    value: "after_inclusive",
    isAvailable: (field, meta) =>
      !["household_size", ...retargetingFields].includes(field) ||
      isSurveySingleAnswerAvailable(field, meta),
    availableTypes: "date (YYYY-MM-DD)",
    helpText:
      "The value you set here must be a date (YYYY-MM-DD). If the selected field is the same date or later, the record will match.",
  },
  {
    name: "is at least x years old",
    value: "is_greater_than_years_old",
    isAvailable: (field, meta) =>
      !["household_size", ...retargetingFields].includes(field) ||
      isSurveySingleAnswerAvailable(field, meta),
    availableTypes: "a number",
    helpText:
      "The value you set here must be a number. If the selected field is a date more than x years old, the record will match.",
  },
  {
    name: "is at most x years old",
    value: "is_less_than_years_old",
    isAvailable: (field, meta) =>
      !["household_size", ...retargetingFields].includes(field) ||
      isSurveySingleAnswerAvailable(field, meta),
    availableTypes: "a number",
    helpText:
      "The value you set here must be a number. If the selected field is a date less than x years old, the record will match.",
  },
  {
    name: "is empty",
    value: "is_empty",
    isAvailable: field => !retargetingFields.includes(field),
    hideValueInput: true,
  },
  {
    name: "is not empty",
    value: "is_not_empty",
    isAvailable: field => !retargetingFields.includes(field),
    hideValueInput: true,
  },
  {
    name: "was not answered",
    value: "is_empty",
    isAvailable: (field, meta) =>
      isSurveySingleAnswerAvailable(field, meta) || isSurveyMultiAnswerAvailable(field, meta),
  },
  {
    name: "was answered",
    value: "is_not_empty",
    isAvailable: (field, meta) =>
      isSurveySingleAnswerAvailable(field, meta) || isSurveyMultiAnswerAvailable(field, meta),
  },
  {
    name: "is active",
    value: "is_active",
    isAvailable: field => field === "journey_status",
  },
  {
    name: "is expired",
    value: "is_expired",
    isAvailable: field => field === "journey_status",
  },
  {
    name: "is completed",
    value: "is_completed",
    isAvailable: field => field === "journey_status",
  },
  {
    name: "is expired or completed",
    value: "is_expired_or_completed",
    isAvailable: field => field === "journey_status",
  },
  {
    name: "is not completed",
    value: "is_not_completed",
    isAvailable: field => field === "journey_status",
  },
  {
    name: "journey exists",
    value: "exists",
    isAvailable: field => field === "journey_status",
  },
  {
    name: "journey does not exist",
    value: "not_exists",
    isAvailable: field => field === "journey_status",
  },
  {
    name: "has opened journey",
    value: "is_opened",
    isAvailable: field => field === "journey_status",
  },
  {
    name: "has not opened journey",
    value: "is_not_opened",
    isAvailable: field => field === "journey_status",
  },
  {
    name: "has viewed any page",
    value: "is_viewed_page",
    isAvailable: field => field === "journey_status",
  },
  {
    name: "has not viewed any page",
    value: "is_not_viewed_page",
    isAvailable: field => field === "journey_status",
  },
  {
    name: "has clicked any message",
    value: "is_clicked_message",
    isAvailable: field => field === "journey_status",
  },
  {
    name: "has not clicked any message",
    value: "is_not_clicked_message",
    isAvailable: field => field === "journey_status",
  },
  {
    name: "has mobile app download sms sent",
    value: "is_sms_sent",
    isAvailable: field => field === "mobile_app_download",
  },
  {
    name: "has not requested mobile app download",
    value: "is_not_sms_sent",
    isAvailable: field => field === "mobile_app_download",
  },
  {
    name: "has clicked mobile app download link",
    value: "is_link_clicked",
    isAvailable: field => field === "mobile_app_download",
  },
  {
    name: "has not clicked mobile app download link",
    value: "is_not_link_clicked",
    isAvailable: field => field === "mobile_app_download",
  },
  /* Operators common to Direct Deposit and Card On File */
  {
    name: "has completed",
    value: "is_completed",
    isAvailable: field => ["card_on_file", "direct_deposit"].includes(field),
  },
  {
    name: "has not completed",
    value: "is_not_completed",
    isAvailable: field => ["card_on_file", "direct_deposit"].includes(field),
  },
  {
    name: "has completed switch with employer",
    value: "is_completed_direct_deposit_employer",
    isAvailable: field => field === "direct_deposit",
  },
  {
    name: "has distribution type",
    value: "is_direct_deposit_distribution_type",
    isAvailable: field => field === "direct_deposit",
  },
  {
    name: "has failed",
    value: "is_failed",
    isAvailable: field => ["card_on_file", "direct_deposit"].includes(field),
  },
  {
    name: "has not failed",
    value: "is_not_failed",
    isAvailable: field => ["card_on_file", "direct_deposit"].includes(field),
  },
  {
    name: "has failed switch with employer",
    value: "is_failed_direct_deposit_employer",
    isAvailable: field => field === "direct_deposit",
  },
  {
    name: "has failed switch with reason",
    value: "is_failed_direct_deposit_reason",
    isAvailable: field => field === "direct_deposit",
  },
  {
    name: "has abandoned",
    value: "is_abandoned",
    isAvailable: field => ["card_on_file", "direct_deposit"].includes(field),
  },
  /* Operators specific to Card On File */
  {
    name: "has not abandoned",
    value: "is_not_abandoned",
    isAvailable: field => field === "card_on_file",
  },
  {
    name: "has placed card",
    value: "has_placed_card",
    isAvailable: field => field === "card_on_file",
  },
  {
    name: "has failed to place card",
    value: "has_failed_to_place_card",
    isAvailable: field => field === "card_on_file",
  },
  {
    name: "encountered failure",
    value: "encountered_failure",
    isAvailable: field => field === "card_on_file",
  },
  /* Operators specific to Direct Deposit */
  {
    name: "has started",
    value: "is_started",
    isAvailable: field => field === "direct_deposit",
  },
  {
    name: "has not started",
    value: "is_not_started",
    isAvailable: field => field === "direct_deposit",
  },
  {
    name: "has searched for direct deposit company",
    value: "is_searched_direct_deposit_company",
    isAvailable: field => field === "direct_deposit",
  },
  {
    name: "has searched for direct deposit payroll",
    value: "is_searched_direct_deposit_payroll",
    isAvailable: field => field === "direct_deposit",
  },
  {
    name: "has accepted enrollment",
    value: "is_accepted",
    isAvailable: field => field === "enrollment",
  },
  {
    name: "has declined enrollment",
    value: "is_declined",
    isAvailable: field => field === "enrollment",
  },
  {
    name: "has started enrollment",
    value: "is_started",
    isAvailable: field => field === "enrollment",
  },
  {
    name: "has clicked enrollment terms",
    value: "is_link_clicked",
    isAvailable: field => field === "enrollment",
  },
  {
    name: "has abandoned enrollment",
    value: "is_abandoned",
    isAvailable: field => field === "enrollment",
  },
  {
    name: "has started survey",
    value: "is_started",
    isAvailable: isSurveyEntireAvailable,
  },
  {
    name: "has completed survey",
    value: "is_completed",
    isAvailable: isSurveyEntireAvailable,
  },
  {
    name: "has abandoned survey",
    value: "is_abandoned",
    isAvailable: isSurveyEntireAvailable,
  },
  /* Operators specific to verified/opt-in/authorized */
  {
    name: "is verified",
    value: "is_verified",
    isAvailable: field => ["email", "phone_mobile"].includes(field),
    hideValueInput: true,
  },
  {
    name: "is not verified",
    value: "is_not_verified",
    isAvailable: field => ["email", "phone_mobile"].includes(field),
    hideValueInput: true,
  },
  {
    name: "is opted-in",
    value: "is_opted_in",
    isAvailable: field => ["email", "phone_mobile"].includes(field),
    hideValueInput: true,
  },
  {
    name: "is not opted-in",
    value: "is_not_opted_in",
    isAvailable: field => ["email", "phone_mobile"].includes(field),
    hideValueInput: true,
  },
  {
    name: "is authorized",
    value: "is_authorized",
    isAvailable: field => ["email", "phone_mobile"].includes(field),
    hideValueInput: true,
  },
  {
    name: "is not authorized",
    value: "is_not_authorized",
    isAvailable: field => ["email", "phone_mobile"].includes(field),
    hideValueInput: true,
  },
  {
    name: "has opted in email",
    value: "is_opted_in_email",
    isAvailable: field => field === "opt_in",
  },
  {
    name: "has opted in mobile number",
    value: "is_opted_in_mobile_number",
    isAvailable: field => field === "opt_in",
  },
  {
    name: "has opted out email",
    value: "is_opted_out_email",
    isAvailable: field => field === "opt_in",
  },
  {
    name: "has opted out SMS",
    value: "is_opted_out_sms",
    isAvailable: field => field === "opt_in",
  },
  {
    name: "has email verified",
    value: "is_verified_email",
    isAvailable: field => field === "opt_in",
  },
  {
    name: "has mobile number verified",
    value: "is_verified_mobile_number",
    isAvailable: field => field === "opt_in",
  },
]

// NB: `injectCustomOperators` is used when displaying dynamic operators that do not fit neatly into
// the prescribed `availableOperators` list. The intended idea is that we have a single source of
// truth for injecting dynamic operators. Extend this as needed.
export const injectCustomOperators = (field, meta) => {
  if (isSurveyMultiAnswerAvailable(field, meta))
    return [
      ...meta.answers.flatMap(({atomizedName, name}) => [
        {
          name: `Selected Answer ${name}`,
          value: `is_selected::${atomizedName}`,
          isAvailable: isSurveyMultiAnswerAvailable,
        },
        {
          name: `Did not select Answer ${name}`,
          value: `is_not_selected::${atomizedName}`,
          isAvailable: isSurveyMultiAnswerAvailable,
        },
      ]),
      ...availableOperators,
    ]

  return availableOperators
}

// Converts strings to their atomized form.
//
// Examples:
//
// atomizeName(" Blue Earrings ")
// "blue earrings"
//
// atomizeName(" My faVorite SunGlasses     ")
// "my favorite sunglasses"
export const atomizeName = name => {
  if (typeof name === "string") return name.toLowerCase().trim()

  return name
}

// Converts atomized names to Title Case.
// FOO BAR --> Foo Bar
// --foo-bar-- --> Foo Bar
// __FOO_BAR__ --> Foo Bar
// FOO --> Foo
export const humanizeAtomizedNames = name => {
  if (typeof name === "string") return startCase(atomizeName(name))

  return name
}

// Intended to be used by a reducer to discard similar values set by key or keys. Use keys when
// uniqueness has to meet multiple constraints.
//
// Examples:
//
// uniqueBy("name")([{id: 1, name: "Blue"}, {id: 2, name: "Blue"}])
// [{id: 1, name: "Blue"}]
//
// uniqueBy(["surveyName", "title"])([
//    {id: 1, surveyName: "Survey", questionTitle: "How's the weather?"},
//    {id: 2, surveyName: "Survey", questionTitle: "What is your favorite color?"},
//    {id: 3, surveyName: "Survey", questionTitle: "What is your favorite color?"},
// ])
// [
//    {id: 1, surveyName: "Survey", questionTitle: "How's the weather?"},
//    {id: 2, surveyName: "Survey", questionTitle: "What is your favorite color?"},
// ]
export const uniqueBy = (
  key,
  compare = (a, b) => a.toLowerCase().trim() === b.toLowerCase().trim()
) => (acc, cur) => {
  const keys = Array.isArray(key) ? key : [key]

  if (acc.find(a => keys.every(k => compare(a[k], cur[k])))) return acc

  return [...acc, cur]
}

// Stateful targeting impact was added after the initial implementation of the form state.
// This function determines if and how targeting impact should drive form state.
export const targetingImpactFormState = targetingImpact => {
  if (targetingImpact?.state) {
    switch (targetingImpact.state) {
      case TARGETING_IMPACT.AVAILABLE:
      case TARGETING_IMPACT.COMPLETED:
        return FORM_STATES.PRIOR_IMPACT
      case TARGETING_IMPACT.EXECUTING:
        return FORM_STATES.CHECKING_IMPACT
      case TARGETING_IMPACT.CANCELLED:
      case TARGETING_IMPACT.FAILED:
      case TARGETING_IMPACT.NONE:
      default:
        return null
    }
  }
  return null
}
