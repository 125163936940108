import {
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func, string} from "prop-types"
import {useEffect, useState} from "react"
import {connect} from "react-redux"

import {requestWebhookTest} from "lib/api"
import {toCamelCase} from "lib/case-converter"
import {requiredField} from "lib/field-validations"
import useFeatures from "lib/hooks/use-features"
import useForm from "lib/hooks/use-form"

import {createHandler, useSocket} from "../../contexts/socket-manager"
import DOSelect from "../do-select/do-select"
import Padded from "../padded/padded"
import SaveButton from "../save-button/save-button"
import WebhookLogDetails from "./webhook-logs/webhook-log-details"

const isDisabled = inputs => {
  const type = inputs?.type?.value

  switch (type) {
    case "ContactCreated":
    case "ContactUpdated":
      return !inputs?.id?.value
    case "ContactUnsubscribed":
      return !(inputs?.channel?.value && inputs?.contactId?.value)
    case "CtaClicked":
      return !(inputs?.contentBlockId?.value && inputs?.journeyId?.value)
    case "EnrollmentAccepted":
    case "EnrollmentDeclined":
    case "SurveyCompleted":
      return !(
        inputs?.id?.value &&
        inputs?.journeyId?.value &&
        inputs?.contactId?.value &&
        inputs?.contentBlockId?.value &&
        inputs?.pageId?.value &&
        inputs?.objectiveId?.value &&
        inputs["meta.name"]?.value
      )
    case "ObjectiveCompleted":
      return !(inputs?.journeyId?.value && inputs?.contactId?.value && inputs?.objectiveId?.value)
    case "RewardEarned":
      return !(
        inputs?.actionId?.value &&
        inputs?.contactId?.value &&
        inputs?.id?.value &&
        inputs?.journeyId?.value &&
        inputs?.rewardSetId?.value &&
        inputs?.templateId?.value
      )
    case "TargetingFailed":
      return (
        !inputs?.errorMessage?.value && !inputs?.errorType?.value && !inputs?.templateName?.value
      )
    default:
      return true
  }
}

const WebhookDialog = ({onClose, teamId, webhook}) => {
  const onSubmit = formData => {
    const compilePayload = (raw, url) => {
      switch (raw.type) {
        case "ContactCreated":
        case "ContactUpdated":
          return {id: raw.id, type: raw.type, webhook}
        case "ContactUnsubscribed":
          return {
            channel: raw.channel,
            contactId: raw.contactId,
            type: raw.type,
            webhook: url,
          }
        case "CtaClicked":
          return {
            contentBlockId: raw.contentBlockId,
            journeyId: raw.journeyId,
            type: raw.type,
            webhook: url,
          }
        case "EnrollmentAccepted":
        case "EnrollmentDeclined":
          return {
            enrolled: raw.type === "EnrollmentAccepted",
            contactId: raw.contactId,
            contentBlockId: raw.contentBlockId,
            id: raw.id,
            journeyId: raw.journeyId,
            meta: raw.meta,
            objectiveId: raw.objectiveId,
            pageId: raw.pageId,
            type: raw.type,
            webhook: url,
          }
        case "ObjectiveCompleted":
          return {
            contactId: raw.contactId,
            journeyId: raw.journeyId,
            objectiveId: raw.objectiveId,
            type: raw.type,
            webhook: url,
          }
        case "RewardEarned":
          return {
            actionId: raw.actionId,
            contactId: raw.contactId,
            id: raw.id,
            journeyId: raw.journeyId,
            rewardSetId: raw.rewardSetId,
            templateId: raw.templateId,
            type: raw.type,
            webhook: url,
          }
        case "SurveyCompleted":
          return {
            contactId: raw.contactId,
            contentBlockId: raw.contentBlockId,
            id: raw.id,
            journeyId: raw.journeyId,
            meta: raw.meta,
            objectiveId: raw.objectiveId,
            pageId: raw.pageId,
            type: raw.type,
            webhook: url,
          }
        case "TargetingFailed":
          return {
            errorMessage: raw.errorMessage,
            errorType: raw.errorType,
            templateName: raw.templateName,
            type: raw.type,
            webhook: url,
          }
        default:
          return {}
      }
    }
    setIsWaiting(true)
    requestWebhookTest(compilePayload(formData, webhook)).catch(() => {
      setIsWaiting(false)
      setDidError(true)

      // Use default message
      setErrorMessage(null)
    })
  }

  const requiredIfContactCreatedOrUpdated = (val, {type: {value: type}}) =>
    ["ContactCreated", "ContactUpdated"].includes(type) && !val ? "Required" : null
  const requiredIfContactUnsubscribed = (val, {type: {value: type}}) =>
    type === "ContactUnsubscribed" && !val ? "Required" : null
  const requiredIfCtaClicked = (val, {type: {value: type}}) =>
    type === "CtaClicked" && !val ? "Required" : null

  const {failed, field, handleSubmit, inputs, invalid, submitting} = useForm({
    onSubmit,
    validators: {
      channel: [requiredIfContactUnsubscribed],
      contactId: [requiredIfContactUnsubscribed],
      contentBlockId: [requiredIfCtaClicked],
      id: [requiredIfContactCreatedOrUpdated],
      journeyId: [requiredIfCtaClicked],
      type: [requiredField],
    },
  })
  const {addHandler, removeHandler} = useSocket()

  const [errorMessage, setErrorMessage] = useState(null)
  const [didError, setDidError] = useState(false)
  const [isWaiting, setIsWaiting] = useState(false)

  const [response, setResponse] = useState(null)

  const classes = useStyles()
  const type = inputs?.type?.value
  const {hasFeature} = useFeatures()

  useEffect(() => {
    const webhookTestCompleted = createHandler(
      `webhook-test:${teamId}`,
      "webhook_test_completed",
      _response => {
        setIsWaiting(false)
        setDidError(false)
        setErrorMessage(null)
        setResponse(toCamelCase(_response, {ignoreKeys: ["body", "headers"]}))
      }
    )

    addHandler(webhookTestCompleted)

    const webhookTestUnexpectedError = createHandler(
      `webhook-test:${teamId}`,
      "webhook_test_unexpected_error",
      ({message}) => {
        setIsWaiting(false)
        setDidError(true)
        setErrorMessage(message)
        setResponse(null)
      }
    )

    addHandler(webhookTestUnexpectedError)

    return () => {
      removeHandler(webhookTestCompleted)
      removeHandler(webhookTestUnexpectedError)
    }
  }, [addHandler, removeHandler, teamId])

  return (
    <Dialog
      classes={{paper: classes.paper}}
      fullWidth={true}
      maxWidth={false}
      onClose={onClose}
      open={true}
    >
      <DialogContent>
        <Padded>
          <Typography className={classes.spacingBottom} variant="h5">
            Webhook Test
          </Typography>
          <Typography className={classes.spacingBottom}>
            Choose the type of notification you want to send and an id to send with it.
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container={true} spacing={1}>
              <Grid container={true} item={true} spacing={4}>
                <Grid item={true} lg={type ? 6 : 12} md={12} xs={12}>
                  <FormControl fullWidth={true}>
                    <InputLabel>Notification</InputLabel>
                    <DOSelect
                      classes={{root: classes.select}}
                      className={classes.field}
                      fullWidth={true}
                      limitHeight={false}
                      {...field("type", {exclude: ["helperText"]})}
                    >
                      <MenuItem value="ContactCreated">contact.created</MenuItem>
                      <MenuItem value="ContactUpdated">contact.updated</MenuItem>
                      <MenuItem value="ContactUnsubscribed">contact.unsubscribed</MenuItem>
                      <MenuItem value="CtaClicked">cta.clicked</MenuItem>
                      <MenuItem value="EnrollmentAccepted">enrollment.accepted</MenuItem>
                      <MenuItem value="EnrollmentDeclined">enrollment.declined</MenuItem>
                      <MenuItem value="ObjectiveCompleted">objective.completed</MenuItem>
                      {hasFeature("rewards") && (
                        <MenuItem value="RewardEarned">reward.earned</MenuItem>
                      )}
                      <MenuItem value="SurveyCompleted">survey.completed</MenuItem>
                      <MenuItem value="TargetingFailed">targeting.failed</MenuItem>
                    </DOSelect>
                  </FormControl>
                </Grid>
                {type && ["ContactCreated", "ContactUpdated"].includes(type) && (
                  <Grid item={true} lg={6} md={12} xs={12}>
                    <TextField fullWidth={true} label="Contact ID" {...field("id")} />
                  </Grid>
                )}
                {type && type === "ContactUnsubscribed" && (
                  <Grid item={true} lg={6} md={12} xs={12}>
                    <FormControl fullWidth={true}>
                      <InputLabel>Channel</InputLabel>
                      <DOSelect
                        classes={{root: classes.select}}
                        className={classes.field}
                        fullWidth={true}
                        limitHeight={false}
                        {...field("channel", {exclude: ["helperText"]})}
                      >
                        <MenuItem value="email">Email</MenuItem>
                        <MenuItem value="sms">SMS</MenuItem>
                      </DOSelect>
                    </FormControl>
                    <TextField fullWidth={true} label="Contact ID" {...field("contactId")} />
                  </Grid>
                )}
                {type && type === "CtaClicked" && (
                  <Grid item={true} lg={6} md={12} xs={12}>
                    <TextField fullWidth={true} label="Journey ID" {...field("journeyId")} />
                    <TextField
                      fullWidth={true}
                      label="Content Block ID"
                      {...field("contentBlockId")}
                    />
                  </Grid>
                )}
                {type &&
                  ["EnrollmentAccepted", "EnrollmentDeclined", "SurveyCompleted"].includes(
                    type
                  ) && (
                    <Grid item={true} lg={6} md={12} xs={12}>
                      <TextField fullWidth={true} label="ID" {...field("id")} />
                      <TextField fullWidth={true} label="Journey ID" {...field("journeyId")} />
                      <TextField fullWidth={true} label="Contact ID" {...field("contactId")} />
                      <TextField
                        fullWidth={true}
                        label="Content Block ID"
                        {...field("contentBlockId")}
                      />
                      <TextField fullWidth={true} label="Page ID" {...field("pageId")} />
                      <TextField fullWidth={true} label="Objective ID" {...field("objectiveId")} />
                      <TextField fullWidth={true} label="Name" {...field("meta.name")} />
                    </Grid>
                  )}
                {type && ["ObjectiveCompleted"].includes(type) && (
                  <Grid item={true} lg={6} md={12} xs={12}>
                    <TextField fullWidth={true} label="Journey ID" {...field("journeyId")} />
                    <TextField fullWidth={true} label="Contact ID" {...field("contactId")} />
                    <TextField fullWidth={true} label="Objective ID" {...field("objectiveId")} />
                  </Grid>
                )}
                {type && ["RewardEarned"].includes(type) && (
                  <Grid item={true} lg={6} md={12} xs={12}>
                    <TextField fullWidth={true} label="ID" {...field("id")} />
                    <TextField fullWidth={true} label="Template ID" {...field("templateId")} />
                    <TextField fullWidth={true} label="Journey ID" {...field("journeyId")} />
                    <TextField fullWidth={true} label="Contact ID" {...field("contactId")} />
                    <TextField fullWidth={true} label="Action ID" {...field("actionId")} />
                    <TextField fullWidth={true} label="Reward Set ID" {...field("rewardSetId")} />
                  </Grid>
                )}
                {type && type === "TargetingFailed" && (
                  <Grid item={true} lg={6} md={12} xs={12}>
                    <TextField fullWidth={true} label="Error Message" {...field("errorMessage")} />
                    <TextField fullWidth={true} label="Error Type" {...field("errorType")} />
                    <TextField fullWidth={true} label="Campaign Name" {...field("templateName")} />
                  </Grid>
                )}
              </Grid>

              <Grid container={true} item={true} justifyContent="flex-end">
                <Grid item={true}>
                  <SaveButton
                    disabled={invalid || isDisabled(inputs)}
                    failed={failed || didError}
                    stateLabels={{default: "Send", submitting: "Sending...", saved: "Sent!"}}
                    submitting={submitting || isWaiting}
                  />
                </Grid>
              </Grid>

              {response && (
                <Grid container={true} item={true}>
                  <Grid classes={{item: classes.responseItem}} item={true}>
                    {/*
                        Note: didError isn't being utilized here because we are not
                        rendering if we don't have a response. Just a heads up that
                        we should never reach this case https://gitlab.com/digitalonboarding/hendricks/-/blob/126a127055aa5b697a6c0612ed4e30d01067d173/src/components/teams/webhook-logs/webhook-log-details.jsx#L115
                    */}
                    <WebhookLogDetails
                      classes={{padded: classes.padded}}
                      didError={didError}
                      webhookDetails={response}
                    />
                  </Grid>
                </Grid>
              )}
              {didError && (
                <Typography className={classes.errorMessage}>
                  {errorMessage || "Something went wrong. Please try again."}
                </Typography>
              )}
            </Grid>
          </form>
        </Padded>
      </DialogContent>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  errorMessage: {
    color: theme.palette.error.main,
  },
  padded: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  paper: {
    maxWidth: 800,
  },
  responseItem: {
    width: "100%",
  },
  spacingBottom: {
    marginBottom: theme.spacing(2),
  },
}))

WebhookDialog.propTypes = {
  onClose: func.isRequired,
  teamId: string.isRequired,
  webhook: string.isRequired,
}

const mapStateToProps = ({session}) => ({
  teamId: session.team?.id,
})

export default connect(mapStateToProps)(WebhookDialog)
